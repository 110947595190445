import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';

import {FaTwitterSquare,FaLinkedin,FaFacebookSquare,FaEnvelopeSquare,FaCreativeCommons} from 'react-icons/fa'
function Footer(){
    const style = {
        backgroundColor: "#F8F8F8",
        borderTop: "1px solid #E7E7E7",
        textAlign: "center",
        padding: "10px",
        position: "relative",
        left: "0",
        bottom: "0",
        height: "120px",
        width: "100%"
      };

return (

  <div style={{width:'100%',backgroundColor:'#F8F8F8'}}>
    
  
    <Container  >
    <Navbar sticky='bottom' expand="sm" style={style} >
        
          <Row style={{width:'100%'}}>
            <Col style={{width:'100%'}}>
                <Col>
                    <h6 className="text-uppercase mb-2">Contact</h6>
                </Col>
              <Col>
                  <a href="https://www.hsph.harvard.edu/biostatistics/" target="_blank" className="text-decoration-none">Department of Biostatistics</a>
              </Col>
              <Col>
              <a href="https://www.hsph.harvard.edu/" target="_blank" className="text-decoration-none">Harvard T.H. Chan School of Public Health</a>

              </Col>
            </Col>
            <Col lg>
              <h6 className="text-uppercase mb-2">License</h6>
              <a className="nav-item nav-link" target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/"><FaCreativeCommons size={28}/></a>
            </Col>
            <Col>
              <h6 className="text-uppercase mb-2">Share</h6>
              <a target="_blank" href="http://www.facebook.com/sharer/sharer.php?u=http://hsph.harvard.edu" type="button" className="btn-floating btn-fb">
                <FaFacebookSquare size={28}/>
              </a>
              <a type="button" target="_blank" className="btn-floating btn-fb" href="https://twitter.com/intent/tweet?text=NetZooCloud: netZooCloud is a web server that runs netZoo tools for gene regulatory network inference and analysis on the cloud. https://netzoocloud.networkmedicine.org">
                <FaTwitterSquare size={28}/>
              </a>
              <a  target="_blank" href="https://www.linkedin.com/sharing/share-offsite/?url=https://netzoocloud.networkmedicine.org" type="button" className="btn-floating btn-fb">
                <FaLinkedin size={28}/>
              </a>
              <a  target="_blank" href="/contact" type="button" className="btn-floating btn-fb">
                <FaEnvelopeSquare size={28}/>
              </a>
            </Col>
          </Row>
    </Navbar>
    </Container>
</div>
 
)
}
export default Footer