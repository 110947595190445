export const initialState = {
    pipeline_state:{
        panda_state_pipeline:{},
        lioness_state_pipeline:{},
        puma_state_pipeline:{},
        alpaca_state_pipeline:{},
        monster_state_pipeline:{},
        dragon_state_pipeline:{},
        condor_state_pipeline:{},
        otter_state_pipeline:{},
        crane_state_pipeline:{}

    },
    panda_state : {
    numAnalysis: 0,
     input_state:{
        submit_disabled : true,
        input_files: {exp_file:null, motif_file:null,ppi_file:null},
        uid: ""
     },
     analysis_state:{
         alpha: 0.1,
         mode: 'legacy',
         precision: 'double',
         max_sample_num : 1,
         analysis_tab_disabled: true,
     },
     vis_state: {
         vis_tab_disabled: true,
         panda_results_table_data_full: [],
         panda_results_table_data_trimmed: [],
         network_data: { "bfs": {"cy": null },"circle": {"cy": null},'cise': {'cy': null},'fcose': {'cy': null}},
         table_type: [],
         
 
     },
     target_state:{
         target_tab_disabled : true,

     },
     gene_enrichment_state:{
        gene_enrichment_tab_disabled : true,
        num_genes : 0,
        num_tfs: 0,
     },
     download_state:{
         down_tab_disabled:true,
 
     },
    },
    alpaca_state : {
        numAnalysis: 0,
        input_state:{
           submit_disabled : true,
           input_files: {network_file1: null,network_file2: null},
           uid: ""
        },
        activeKey:"Input",
        analysis_state:{
            analysis_tab_disabled: true,
        },
        vis_state: {
            vis_tab_disabled: true,
            panda_results_table_data_full: [],
            panda_results_table_data_trimmed: [],
            network_data: { "bfs": {"cy": null},"circle": {"cy": null},'cise': {'cy': null},'fcose': {'cy': null}},
            table_type: [],
            
    
        },
        mod_state:{
            "modularity": null,
            'commIDs': {'tfs': [],'genes': []},
           
        },
  
        
        network_state:{},
        target_state:{
            target_tab_disabled : true,
   
        },
        gene_enrichment_state:{
           gene_enrichment_tab_disabled : true,
           num_genes : 0,
           num_tfs: 0,
        },
        download_state:{
            down_tab_disabled:true,
    
        },
        },
        crane_state : {
            numAnalysis: 0,
            input_state:{
               submit_disabled : true,
               input_files: {network_file1: null,network_file2: null},
               uid: ""
            },
            activeKey:"Input",
            analysis_state:{
                alpha : null,
                beta:null,
                iterations: null,
                analysis_tab_disabled: true,
            },
            vis_state: {
                vis_tab_disabled: true,
                panda_results_table_data_full: [],
                panda_results_table_data_trimmed: [],
                network_data: { "bfs": {"cy": null},"circle": {"cy": null},'cise': {'cy': null},'fcose': {'cy': null}},
                table_type: [],
                
        
            },
            mod_state:{
                "modularity": null,
                 'commIDs': {'tfs': [],'genes': []},
               
            },
      
            
            network_state:{},
            target_state:{
                target_tab_disabled : true,
       
            },
            gene_enrichment_state:{
               gene_enrichment_tab_disabled : true,
               num_genes : 0,
               num_tfs: 0,
            },
            download_state:{
                down_tab_disabled:true,
        
            },
            },
    
    condor_state : {
        numAnalysis: 0,
         input_state:{
            submit_disabled : true,
            input_files: {network_file: null},
            uid: ""
         },
         analysis_state:{
             analysis_tab_disabled: true,
         },
         vis_state: {
             vis_tab_disabled: true,
             panda_results_table_data_full: [],
             panda_results_table_data_trimmed: [],
             network_data: { "bfs": {"cy": null },"circle": {"cy": null},'cise': {'cy': null},'fcose': {'cy': null}},
             table_type: [],
             
     
         },
         mod_state:{
             "modularity": null,
             'commIDs': {'tfs': [],'genes': []},
            
         },
         network_state:{},
         target_state:{
             target_tab_disabled : true,
    
         },
         gene_enrichment_state:{
            gene_enrichment_tab_disabled : true,
            num_genes : 0,
            num_tfs: 0,
         },
         download_state:{
             down_tab_disabled:true,
     
         },
        },
    lioness_state : {
        numAnalysis: 0,
        activeKey: "Input",
        input_state:{
           submit_disabled : true,
           input_files: {exp_file:null, motif_file:null,mir_file:null,ppi_file:null},
           input_mode: null,
           uid: ""

        },
        analysis_state:{
            alpha: 0.1,
            mode: 'legacy',
            start: 0 ,
            end: 0,
            max_sample_num : 1,
            precision: 'double',
            lioness_results_file : "",
            analysis_tab_disabled: true,
            linkedBy: "None",
            animal_obj: "",
        },
        vis_state: {
            vis_tab_disabled: true,
            lioness_results_table_data_full: [],
            lioness_results_table_data_trimmed: [],
            network_data: { "bfs": {"cy": null},"circle": {"cy": null},'cise': {'cy': null},'fcose': {'cy': null}},
            table_type: [],
            
    
        },
        target_state:{
            target_tab_disabled : true,
    
        },
        gene_enrichment_state:{
            gene_enrichment_tab_disabled : true,
            num_genes : 0,
            num_tfs: 0,
         },
        download_state:{
            down_tab_disabled:true,
    
        },
       },
    puma_state : {
    numAnalysis: 0,
    activeKey:"Input",
     input_state:{
        submit_disabled : true,
        input_files: {exp_file:null, motif_file:null,mir_file:null,ppi_file:null},
        uid: ""   
     },
     analysis_state:{
         alpha : 0.1,
         mode: 'legacy',
         precision : 'double',
         puma_results_file : "",
         max_sample_num : 1,
         analysis_tab_disabled: true,
         linkedBy: "None"
     },
     vis_state: {
         vis_tab_disabled: true,
         puma_results_table_data_full: [],
         puma_results_table_data_trimmed: [],
         adj_matrix: null,
         table_type: [],
         network_data: {"bfs": {"cy": null},"circle": {"cy": null},'cise': {'cy': null},'fcose': {'cy': null}}
         
 
     },
     target_state:{
        target_tab_disabled : true,

    },
    gene_enrichment_state:{
        gene_enrichment_tab_disabled : true,
        num_genes : 0,
        num_tfs: 0,
     },

     download_state:{
         down_tab_disabled:true,
 
     },
    },
    otter_state : {
        numAnalysis: 0,
        input_state:{
           submit_disabled : true,
           input_files: {exp_file:null, motif_file:null,ppi_file:null},
           uid: ""   
        },
        analysis_state:{
            mode: null,
            gamma: null,
            lamda: null,
            iteration: null,
            bexp: null,
            eta: null,
            max_sample_num: 0 ,
            analysis_tab_disabled: true,
        },
        vis_state: {
            vis_tab_disabled: true,
            otter_results_table_data_full: [],
            otter_results_table_data_trimmed: [],
            network_data: { "bfs": {"cy": null},"circle": {"cy": null},'cise': {'cy': null},'fcose': {'cy': null}},
            table_type: [],
            
    
        },
        download_state:{
            down_tab_disabled:true,
    
        },
       },
       dragon_state : {
        numAnalysis: 0,
        input_state:{
           submit_disabled : true,
           input_files: {omic1_file:null, omic2_file:null},
           uid: ""   
        },
        analysis_state:{
            lamdas: [],
            dragon_results_file : "",
            analysis_tab_disabled: true,
        },
        vis_state: {
            vis_tab_disabled: true,
            dragon_results_table_data_full: [],
            dragon_results_table_data_trimmed: [],
            network_data: { "bfs": {"cy": null },"circle": {"cy": null},'cise': {'cy': null},'fcose': {'cy': null}},
            table_type: [],
            
    
        },
        download_state:{
            down_tab_disabled:true,
    
        },
       },
       monster_state : {
            numAnalysis: 0,
            input_state:{
            submit_disabled : true,
            input_files: {network_file1: null,network_file2: null},
            uid: ""
            },
            activeKey:"Input",
            analysis_state:{
                analysis_tab_disabled: true,
            },
            vis_state: {
                vis_tab_disabled: true,
                panda_results_table_data_full: [],
                panda_results_table_data_trimmed: [],
                network_data: { "bfs": {"cy": null },"circle": {"cy": null},'cise': {'cy': null},'fcose': {'cy': null}},
                table_type: [],
                
        
            },
            mod_state:{
                "modularity": null,
            
            },
    
            
            network_state:{},
            target_state:{
                target_tab_disabled : true,
    
            },
            gene_enrichment_state:{
            gene_enrichment_tab_disabled : true,
            num_genes : 0,
            num_tfs: 0,
            },
            download_state:{
                down_tab_disabled:true,
        
            },
        },
        recount_state:{
            selected_rows1: [],
            selected_rows2: [],
           }
 
 };
 
 const verbose = process.env.VERBOSE === "TRUE" ? true : false

 function reducer(state, action) {
     if (verbose){
        console.log("###########reducer_state#####################");
        console.log("#############action##########################");
        console.log(action.type)
        console.log(action);

     }
     
     
     switch (action.type) {
        case 'SET_RECOUNT_STATE_ROWS':
            const new_state_rec =  { ...state, 
                recount_state: {
                    selected_rows1: action.selected_rows1,
                    selected_rows2: action.selected_rows2
                } 
            };
            console.log("#############state##########################");
            console.log(new_state_rec);
            return new_state_rec;
            break
        case 'SET_RECOUNT_STATE_ROWS1':
            const new_state_rec1  =  { ...state, 
                recount_state: {
                    selected_rows1: action.selected_rows,
                    selected_rows2: state.recount_state.selected_rows2,
                } 
            };
            console.log("#############state##########################");
            console.log(new_state_rec1);
            return new_state_rec1;
            break
        case 'SET_RECOUNT_STATE_ROWS2':
            const new_state_rec2  =  { ...state, 
                recount_state: {
                    selected_rows1: state.recount_state.selected_rows1,
                    selected_rows2: action.selected_rows,
                } 
            };
            console.log("#############state##########################");
            console.log(new_state_rec2);
            return new_state_rec2;
            break  

    //############################ PIPELINE STATES & ACTION START ###################################
    case 'SET_PIPELINE_STATE':
             const new_pipeline_state  =  { ...state,
                
                 pipeline_state: action.pipeline_state,
    
             };
             if (verbose){
                console.log("#############state##########################");
                console.log(new_pipeline_state);
                

             }
             return new_pipeline_state;
             
             break
    //##################################### PANDA PIPELINE STATES & ACTION START ###################################
    case 'SET_PIPELINE_PANDA_STATE':
             const new_pipeline_panda_state  =  { ...state,
                
                 pipeline_state: {
                    panda_state_pipeline: action.panda_state_pipeline,
                    puma_state_pipeline: state.pipeline_state.puma_state_pipeline,
                    lioness_state_pipeline: state.pipeline_state.lioness_state_pipeline,
                    otter_state_pipeline: state.pipeline_state.otter_state_pipeline,
                    dragon_state_pipeline: state.pipeline_state.dragon_state_pipeline,
                    condor_state_pipeline: state.pipeline_state.condor_state_pipeline,
                    monster_state_pipeline: state.pipeline_state.monster_state_pipeline,
                    crane_state_pipeline: state.pipeline_state.crane_state_pipeline,
                    alpaca_state_pipeline: state.pipeline_state.alpaca_state_pipeline,
                     
                 } 
             };
             if (verbose){
                console.log("#############state##########################");
                console.log(new_pipeline_panda_state);

             }
             
             return new_pipeline_panda_state;
             break

    case 'SET_PIPELINE_PUMA_STATE':
    const new_pipeline_puma_state  =  { ...state,
        
        pipeline_state: {
            alpaca_state_pipeline: state.pipeline_state.alpaca_state_pipeline,
            panda_state_pipeline: state.pipeline_state.panda_state_pipeline,
            puma_state_pipeline: action.puma_state_pipeline,
            lioness_state_pipeline: state.pipeline_state.lioness_state_pipeline,
            otter_state_pipeline: state.pipeline_state.otter_state_pipeline,
            dragon_state_pipeline: state.pipeline_state.dragon_state_pipeline,
            condor_state_pipeline: state.pipeline_state.condor_state_pipeline,
            monster_state_pipeline: state.pipeline_state.monster_state_pipeline,
            crane_state_pipeline: state.pipeline_state.crane_state_pipeline,
            
        } 
    };
    if (verbose){
        console.log("#############state##########################");
        console.log(new_pipeline_puma_state);

     }
    
    return new_pipeline_puma_state;
    break
    
    case 'SET_PIPELINE_LIONESS_STATE':
    const new_pipeline_lioness_state  =  { ...state,
        
        pipeline_state: {
            alpaca_state_pipeline: state.pipeline_state.alpaca_state_pipeline,
            panda_state_pipeline: state.pipeline_state.panda_state_pipeline,
            puma_state_pipeline: state.pipeline_state.puma_state_pipeline,
            lioness_state_pipeline: action.lioness_state_pipeline,
            otter_state_pipeline: state.pipeline_state.otter_state_pipeline,
            dragon_state_pipeline: state.pipeline_state.dragon_state_pipeline,
            condor_state_pipeline: state.pipeline_state.condor_state_pipeline,
            monster_state_pipeline: state.pipeline_state.monster_state_pipeline,
            crane_state_pipeline: state.pipeline_state.crane_state_pipeline,
            
        } 
    };
    if (verbose){
        console.log("#############state##########################");
    console.log(new_pipeline_lioness_state);

    }
    
    return new_pipeline_lioness_state;
    break

    case 'SET_PIPELINE_DRAGON_STATE':
    const new_pipeline_dragon_state  =  { ...state,
        
        pipeline_state: {
            alpaca_state_pipeline: state.pipeline_state.alpaca_state_pipeline,
            panda_state_pipeline: state.pipeline_state.panda_state_pipeline,
            puma_state_pipeline: state.pipeline_state.puma_state_pipeline,
            lioness_state_pipeline: state.pipeline_state.lioness_state_pipeline,
            otter_state_pipeline: state.pipeline_state.otter_state_pipeline,
            dragon_state_pipeline: action.dragon_state_pipeline,
            condor_state_pipeline: state.pipeline_state.condor_state_pipeline,
            monster_state_pipeline: state.pipeline_state.monster_state_pipeline,
            crane_state_pipeline: state.pipeline_state.crane_state_pipeline,
            
        } 
    };
    
    if (verbose){
        console.log("#############state##########################");
        console.log(new_pipeline_dragon_state);
    }
    return new_pipeline_dragon_state;
    break
    
    case 'SET_PIPELINE_OTTER_STATE':
    const new_pipeline_otter_state  =  { ...state,
        
        pipeline_state: {
            alpaca_state_pipeline: state.pipeline_state.alpaca_state_pipeline,
            panda_state_pipeline: state.pipeline_state.panda_state_pipeline,
            puma_state_pipeline: state.pipeline_state.puma_state_pipeline,
            lioness_state_pipeline: state.pipeline_state.lioness_state_pipeline,
            otter_state_pipeline: action.otter_state_pipeline,
            dragon_state_pipeline: state.pipeline_state.dragon_state_pipeline,
            condor_state_pipeline: state.pipeline_state.condor_state_pipeline,
            monster_state_pipeline: state.pipeline_state.monster_state_pipeline,
            crane_state_pipeline: state.pipeline_state.crane_state_pipeline,
            
        } 
    };
    if(verbose){
        console.log("#############state##########################");
    console.log(new_pipeline_otter_state);

    }
    
    return new_pipeline_otter_state;
    break
                
    case 'SET_PIPELINE_ALPACA_STATE':
    const new_pipeline_alpaca_state  =  { ...state,
        
        pipeline_state: {
            alpaca_state_pipeline: action.alpaca_state_pipeline,
            panda_state_pipeline: state.pipeline_state.panda_state_pipeline,
            puma_state_pipeline: state.pipeline_state.puma_state_pipeline,
            lioness_state_pipeline: state.pipeline_state.lioness_state_pipeline,
            otter_state_pipeline: state.pipeline_state.otter_state_pipeline,
            dragon_state_pipeline: state.pipeline_state.dragon_state_pipeline,
            condor_state_pipeline: state.pipeline_state.condor_state_pipeline,
            monster_state_pipeline: state.pipeline_state.monster_state_pipeline,
            crane_state_pipeline: state.pipeline_state.crane_state_pipeline,
            
        } 
    };
    if (verbose){
        console.log("#############state##########################");
    console.log(new_pipeline_alpaca_state);

    }
    
    return new_pipeline_alpaca_state;
    break

    case 'SET_PIPELINE_CONDOR_STATE':
    const new_pipeline_condor_state  =  { ...state,
        
        pipeline_state: {
            alpaca_state_pipeline: state.pipeline_state.alpaca_state_pipeline,
            panda_state_pipeline: state.pipeline_state.panda_state_pipeline,
            puma_state_pipeline: state.pipeline_state.puma_state_pipeline,
            lioness_state_pipeline: state.pipeline_state.lioness_state_pipeline,
            otter_state_pipeline: state.pipeline_state.otter_state_pipeline,
            dragon_state_pipeline: state.pipeline_state.dragon_state_pipeline,
            condor_state_pipeline: action.condor_state_pipeline,
            monster_state_pipeline: state.pipeline_state.monster_state_pipeline,
            crane_state_pipeline: state.pipeline_state.crane_state_pipeline,
            
        } 
    };
    if (verbose){
        console.log("#############state##########################");
    console.log(new_pipeline_condor_state);

    }
    
    return new_pipeline_condor_state;
    break
    case 'SET_PIPELINE_CRANE_STATE':
    const new_pipeline_crane_state  =  { ...state,
        
        pipeline_state: {
            alpaca_state_pipeline: state.pipeline_state.alpaca_state_pipeline,
            panda_state_pipeline: state.pipeline_state.panda_state_pipeline,
            puma_state_pipeline: state.pipeline_state.puma_state_pipeline,
            lioness_state_pipeline: state.pipeline_state.lioness_state_pipeline,
            otter_state_pipeline: state.pipeline_state.otter_state_pipeline,
            dragon_state_pipeline: state.pipeline_state.dragon_state_pipeline,
            condor_state_pipeline: state.pipeline_state.condor_state_pipeline,
            monster_state_pipeline: state.pipeline_state.monster_state_pipeline,
            crane_state_pipeline: action.crane_state_pipeline,
            
        } 
    };
    if(verbose){
        console.log("#############state##########################");
    console.log(new_pipeline_crane_state);

    }
    
    return new_pipeline_crane_state;
    break

    case 'SET_PIPELINE_MONSTER_STATE':
    const new_pipeline_monster_state  =  { ...state,
        
        pipeline_state: {
            alpaca_state_pipeline: state.pipeline_state.alpaca_state_pipeline,
            panda_state_pipeline: state.pipeline_state.panda_state_pipeline,
            puma_state_pipeline: state.pipeline_state.puma_state_pipeline,
            lioness_state_pipeline: state.pipeline_state.lioness_state_pipeline,
            otter_state_pipeline: state.pipeline_state.otter_state_pipeline,
            dragon_state_pipeline: state.pipeline_state.dragon_state_pipeline,
            condor_state_pipeline: state.pipeline_state.condor_state_pipeline,
            monster_state_pipeline: action.monster_state_pipeline,
            crane_state_pipeline: state.pipeline_state.crane_state_pipeline,
            
        } 
    };
    if(verbose){
        console.log("#############state##########################");
        console.log(new_pipeline_monster_state);

    }
    
    return new_pipeline_monster_state;
    break
    
     //############################ PANDA STATES & ACTION START ###################################
         case 'SET_PANDA_INPUT_STATE':
             const new_state1  =  { ...state,
                
                 panda_state: {
                    numAnalysis: state.panda_state.numAnalysis,
                     input_state: action.input_state,
                     analysis_state:state.panda_state.analysis_state,
                     vis_state:state.panda_state.vis_state,
                     target_state: state.panda_state.target_state,
                     gene_enrichment_state : state.panda_state.gene_enrichment_state,
                     download_state:state.panda_state.download_state
                     
                 } 
             };
             //console.log("#############state##########################");
             //console.log(new_state1);
             return new_state1;
             break
        case 'SET_PANDA_INPUT_STATE_UID':
        const new_state_uid  =  { ...state,
            
            panda_state: {
                numAnalysis: state.panda_state.numAnalysis,
                input_state: {
                    submit_disabled: state.panda_state.input_state.submit_disabled,
                    input_files: state.panda_state.input_state.input_files,
                    uid: action.uid
                },
                analysis_state:state.panda_state.analysis_state,
                vis_state:state.panda_state.vis_state,
                target_state: state.panda_state.target_state,
                gene_enrichment_state : state.panda_state.gene_enrichment_state,
                download_state:state.panda_state.download_state
                
            } 
        };
        if (verbose){
            console.log("#############state##########################");
            console.log(new_state_uid);

        }
        
        return new_state_uid;
        break
        case 'SET_PANDA_NUM_ANALYSIS':
        const new_panda_state1234  =  { ...state,
            
            panda_state: {
                numAnalysis: action.numAnalysis,
                input_state: state.panda_state.input_state,
                analysis_state:state.panda_state.analysis_state,
                vis_state:state.panda_state.vis_state,
                target_state: state.panda_state.target_state,
                gene_enrichment_state : state.panda_state.gene_enrichment_state,
                download_state:state.panda_state.download_state
                
            } 
        };
        if (verbose){
            console.log("#############state##########################");
        console.log(new_panda_state1234);

        }
        
        return new_panda_state1234;
        break
         case 'SET_PANDA_ANALYSIS_STATE':
             const new_state2 = { ...state,
                
                 panda_state: {
                    numAnalysis: state.panda_state.numAnalysis,
                     input_state: state.panda_state.input_state,
                     analysis_state:action.analysis_state,
                     vis_state:state.panda_state.vis_state,
                     target_state: state.panda_state.target_state,
                     gene_enrichment_state : state.panda_state.gene_enrichment_state,
                     download_state:state.panda_state.download_state
                     } 
                 };
             if (verbose){
                console.log("#############state##########################");
                console.log(new_state2);

             }
             
             return new_state2;
             break
         case 'SET_PANDA_VIS_STATE':
             const new_state3 = { ...state, 
                 panda_state: {
                    numAnalysis: state.panda_state.numAnalysis, 
                    input_state: state.panda_state.input_state,
                     analysis_state:state.panda_state.analysis_state,
                     vis_state:action.vis_state,
                     target_state: state.panda_state.target_state,
                     gene_enrichment_state : state.panda_state.gene_enrichment_state,
                     download_state:state.panda_state.download_state
                 } 
             };
             if (verbose){
                 console.log("#############state##########################");
             console.log(new_state3);

             }
             
             return new_state3;
             break
         case 'SET_PANDA_VIS_STATE_TAB_DISABLED':
             const new_state31_tab = { ...state, 
                 panda_state: {
                numAnalysis: state.panda_state.numAnalysis,
                 input_state: state.panda_state.input_state,
                 analysis_state:state.panda_state.analysis_state,
                 vis_state:{
                     vis_tab_disabled:action.vis_tab_disabled,
                     panda_results_table_data_full:state.panda_state.vis_state.panda_results_table_data_full,
                     panda_results_table_data_trimmed:state.panda_state.vis_state.panda_results_table_data_trimmed,
                     table_type: state.panda_state.vis_state.table_type,
                     network_data: state.panda_state.vis_state.network_data
                 },
                 target_state: state.panda_state.target_state,
                 gene_enrichment_state : state.panda_state.gene_enrichment_state,
                 download_state:state.panda_state.download_state} 
             };
             if (verbose){
                 console.log("#############state##########################");
             console.log(new_state31_tab);

             }
             
             return new_state31_tab;
             break
         
         case 'SET_PANDA_VIS_STATE_TABLE_TYPE':
             const new_state31 = { ...state, 
                 panda_state: {
                    numAnalysis: state.panda_state.numAnalysis,
                 input_state: state.panda_state.input_state,
                 analysis_state:state.panda_state.analysis_state,
                 vis_state:{
                     vis_tab_disabled:state.panda_state.vis_state.vis_tab_disabled,
                     panda_results_table_data_full:action.panda_results_table_data_full,
                     panda_results_table_data_trimmed:state.panda_state.vis_state.panda_results_table_data_trimmed,
                     table_type:action.table_type,
                     network_data: state.panda_state.vis_state.network_data
                 },
                 target_state: state.panda_state.target_state,
                 gene_enrichment_state : state.panda_state.gene_enrichment_state,
                 download_state:state.panda_state.download_state} 
             };
             //console.log("#############state##########################");
             //console.log(new_state31);
             return new_state31;
             break
         case 'SET_PANDA_VIS_STATE_TABLE_DATA_FULL':
             const new_state32 = { ...state, 
                 panda_state: {
                    numAnalysis: state.panda_state.numAnalysis,
                     input_state: state.panda_state.input_state,
                     analysis_state:state.panda_state.analysis_state,
                     vis_state:{
                         vis_tab_disabled:state.panda_state.vis_state.vis_tab_disabled,
                         panda_results_table_data_full:action.panda_results_table_data_full,
                         panda_results_table_data_trimmed:state.panda_state.vis_state.panda_results_table_data_trimmed,
                         table_type:state.panda_state.vis_state.table_type,
                         network_data: state.panda_state.vis_state.network_data
                     },
                     target_state: state.panda_state.target_state,
                     gene_enrichment_state : state.panda_state.gene_enrichment_state,
                     download_state:state.panda_state.download_state
                     } 
                 };
             //console.log("#############state##########################");
             //console.log(new_state32);
             return new_state32;
             break
         
         case 'SET_PANDA_VIS_STATE_TABLE_DATA_TRIMMED':
             const new_state33_2 = { ...state, 
                 panda_state: {
                    numAnalysis: state.panda_state.numAnalysis,
                     input_state: state.panda_state.input_state,
                     analysis_state:state.panda_state.analysis_state,
                     vis_state:{
                         vis_tab_disabled:state.panda_state.vis_state.vis_tab_disabled,
                         panda_results_table_data_full:state.panda_state.vis_state.panda_results_table_data_full,
                         panda_results_table_data_trimmed:action.panda_results_table_data_trimmed,
                         table_type:state.panda_state.vis_state.table_type,
                         network_data: state.panda_state.vis_state.network_data},
                    target_state: state.panda_state.target_state,
                    gene_enrichment_state : state.panda_state.gene_enrichment_state,
                     download_state:state.panda_state.download_state} 
                 };
             //console.log("#############state##########################");
             //console.log(new_state33_2);
             return new_state33_2;
             break
         case 'SET_PANDA_VIS_STATE_NETWORK_DATA':
             const new_state34 = { ...state, 
                 panda_state: {
                numAnalysis: state.panda_state.numAnalysis,
                 input_state: state.panda_state.input_state,
                 analysis_state:state.panda_state.analysis_state,
                 vis_state:{
                     vis_tab_disabled:state.panda_state.vis_state.vis_tab_disabled,
                     panda_results_table_data_full:action.panda_results_table_data_full,
                     panda_results_table_data_trimmed:state.panda_state.vis_state.panda_results_table_data_trimmed,
                     table_type:state.panda_state.vis_state.table_type,
                     network_data: action.network_data },
                target_state: state.panda_state.target_state,
                gene_enrichment_state : state.panda_state.gene_enrichment_state,
                 download_state:state.panda_state.download_state} 
             };
             //console.log("#############state##########################");
             //console.log(new_state34);
             return new_state34;
             break
        case 'SET_PANDA_TARGET_STATE':
            const new_state404 = { ...state, 
                panda_state: {
                    numAnalysis: state.panda_state.numAnalysis,
                    input_state: state.panda_state.input_state,
                    analysis_state:state.panda_state.analysis_state,
                    vis_state:state.panda_state.vis_state,
                    target_state: action.target_state,
                    gene_enrichment_state : state.panda_state.gene_enrichment_state,
                    download_state:state.panda_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state404);
            return new_state404;
            break
        case 'SET_PANDA_GENE_ENRICHMENT_STATE':
            const new_state4123 = { ...state, 
                panda_state: {
                    numAnalysis: state.panda_state.numAnalysis,
                    input_state: state.panda_state.input_state,
                    analysis_state:state.panda_state.analysis_state,
                    vis_state:state.panda_state.vis_state,
                    target_state: state.panda_state.target_state,
                    gene_enrichment_state : action.gene_enrichment_state,
                    download_state: state.panda_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state4123);
            return new_state4123;
            break

         case 'SET_PANDA_DOWN_STATE':
             const new_state4 = { ...state, 
                 panda_state: {
                    numAnalysis: state.panda_state.numAnalysis,
                     input_state: state.panda_state.input_state,
                     analysis_state:state.panda_state.analysis_state
                     ,vis_state:state.panda_state.vis_state,
                     target_state: state.panda_state.target_state,
                     gene_enrichment_state : state.panda_state.gene_enrichment_state,
                     download_state:action.download_state
                 } 
             };
             //console.log("#############state##########################");
             //console.log(new_state4);
             return new_state4;
             break
         //############################ PANDA STATES & ACTION END ########################################

         //############################ ALPACA STATES & ACTION START ###################################
         case 'SET_ALPACA_INPUT_STATE':
            const new_alpaca_state1  =  { ...state,
            
                alpaca_state: {
                numAnalysis: state.alpaca_state.numAnalysis,
                activeKey: state.alpaca_state.activeKey,
                    input_state: action.input_state,
                    analysis_state:state.alpaca_state.analysis_state,
                    vis_state:state.alpaca_state.vis_state,
                    mod_state : state.alpaca_state.mod_state,
                    network_state : state.alpaca_state.network_state,
                    target_state: state.alpaca_state.target_state,
                    gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
                    download_state:state.alpaca_state.download_state
                    
                } 
            };
            // console.log("#############state##########################");
            // console.log(new_alpaca_state1);
            return new_alpaca_state1;
            break
        case 'SET_ALPACA_INPUT_STATE_UID':
        const new_alpaca_state_uid  =  { ...state,
        
        alpaca_state: {
            numAnalysis: state.alpaca_state.numAnalysis,
            activeKey: state.alpaca_state.activeKey,
            input_state: {
                submit_disabled: state.alpaca_state.input_state.submit_disabled,
                input_files: state.alpaca_state.input_state.input_files,
                uid: action.uid
            },
            analysis_state:state.alpaca_state.analysis_state,
            mod_state : state.alpaca_state.mod_state,
            network_state : state.alpaca_state.network_state,
            vis_state:state.alpaca_state.vis_state,
            target_state: state.alpaca_state.target_state,
            gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
            download_state:state.alpaca_state.download_state
            
        } 
        };
        //console.log("#############state##########################");
        //console.log(new_alpaca_state_uid);
        return new_alpaca_state_uid;
        break
        case 'SET_ALPACA_NUM_ANALYSIS':
        const new_alpaca_state1234  =  { ...state,
        
        alpaca_state: {
            numAnalysis: action.numAnalysis,
            activeKey: state.alpaca_state.activeKey,
            input_state: state.alpaca_state.input_state,
            analysis_state:state.alpaca_state.analysis_state,
            mod_state : state.alpaca_state.mod_state,
            network_state : state.alpaca_state.network_state,
            vis_state:state.alpaca_state.vis_state,
            target_state: state.alpaca_state.target_state,
            gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
            download_state:state.alpaca_state.download_state
            
        } 
        };
        //console.log("#############state##########################");
        //console.log(new_alpaca_state1234);
        return new_alpaca_state1234;
        break
        case 'SET_ALPACA_ANALYSIS_STATE':
            const new_alpaca_state2 = { ...state,
            
                alpaca_state: {
                numAnalysis: state.alpaca_state.numAnalysis,
                activeKey: state.alpaca_state.activeKey,
                    input_state: state.alpaca_state.input_state,
                    analysis_state:action.analysis_state,
                    mod_state : state.alpaca_state.mod_state,
                    network_state : state.alpaca_state.network_state,
                    vis_state:state.alpaca_state.vis_state,
                    target_state: state.alpaca_state.target_state,
                    gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
                    download_state:state.alpaca_state.download_state
                    } 
                };
            //console.log("#############state##########################");
            //console.log(new_alpaca_state2);
            return new_alpaca_state2;
            break
        case 'SET_ALPACA_VIS_STATE':
            const new_alpaca_state3 = { ...state, 
                alpaca_state: {
                numAnalysis: state.alpaca_state.numAnalysis,
                activeKey: state.alpaca_state.activeKey, 
                input_state: state.alpaca_state.input_state,
                    analysis_state:state.alpaca_state.analysis_state,
                    vis_state:action.vis_state,
                    mod_state : state.alpaca_state.mod_state,
                    network_state : state.alpaca_state.network_state,
                    target_state: state.alpaca_state.target_state,
                    gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
                    download_state:state.alpaca_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_alpaca_state3);
            return new_alpaca_state3;
            break
        case 'SET_ALPACA_VIS_STATE_TAB_DISABLED':
            const new_alpaca_state31_tab = { ...state, 
                alpaca_state: {
            numAnalysis: state.alpaca_state.numAnalysis,
            activeKey: state.alpaca_state.activeKey,
                input_state: state.alpaca_state.input_state,
                analysis_state:state.alpaca_state.analysis_state,
                mod_state : state.alpaca_state.mod_state,
                network_state : state.alpaca_state.network_state,
                vis_state:{
                    vis_tab_disabled:action.vis_tab_disabled,
                    panda_results_table_data_full:state.alpaca_state.vis_state.panda_results_table_data_full,
                    panda_results_table_data_trimmed:state.alpaca_state.vis_state.panda_results_table_data_trimmed,
                    table_type: state.alpaca_state.vis_state.table_type,
                    network_data: state.alpaca_state.vis_state.network_data
                },
                target_state: state.alpaca_state.target_state,
                gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
                download_state:state.alpaca_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_alpaca_state31_tab);
            return new_alpaca_state31_tab;
            break

        case 'SET_ALPACA_VIS_STATE_TABLE_TYPE':
            const new_alpaca_state31 = { ...state, 
                alpaca_state: {
                numAnalysis: state.alpaca_state.numAnalysis,
                activeKey: state.alpaca_state.activeKey,
                input_state: state.alpaca_state.input_state,
                analysis_state:state.alpaca_state.analysis_state,
                mod_state : state.alpaca_state.mod_state,
                network_state : state.alpaca_state.network_state,
                vis_state:{
                    vis_tab_disabled:state.alpaca_state.vis_state.vis_tab_disabled,
                    panda_results_table_data_full:action.panda_results_table_data_full,
                    panda_results_table_data_trimmed:state.alpaca_state.vis_state.panda_results_table_data_trimmed,
                    table_type:action.table_type,
                    network_data: state.alpaca_state.vis_state.network_data
                },
                target_state: state.alpaca_state.target_state,
                gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
                download_state:state.alpaca_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_alpaca_state31);
            return new_alpaca_state31;
            break
        case 'SET_ALPACA_VIS_STATE_TABLE_DATA_FULL':
            const new_alpaca_state32 = { ...state, 
                alpaca_state: {
                numAnalysis: state.alpaca_state.numAnalysis,
                activeKey: state.alpaca_state.activeKey,
                    input_state: state.alpaca_state.input_state,
                    analysis_state:state.alpaca_state.analysis_state,
                    mod_state : state.alpaca_state.mod_state,
                    network_state : state.alpaca_state.network_state,
                    vis_state:{
                        vis_tab_disabled:state.alpaca_state.vis_state.vis_tab_disabled,
                        panda_results_table_data_full:action.panda_results_table_data_full,
                        panda_results_table_data_trimmed:state.alpaca_state.vis_state.panda_results_table_data_trimmed,
                        table_type:state.alpaca_state.vis_state.table_type,
                        network_data: state.alpaca_state.vis_state.network_data
                    },
                    target_state: state.alpaca_state.target_state,
                    gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
                    download_state:state.alpaca_state.download_state
                    } 
                };
            //console.log("#############state##########################");
            //console.log(new_alpaca_state32);
            return new_alpaca_state32;
            break

        case 'SET_ALPACA_VIS_STATE_TABLE_DATA_TRIMMED':
            const new_alpaca_state33_2 = { ...state, 
                alpaca_state: {
                numAnalysis: state.alpaca_state.numAnalysis,
                activeKey: state.alpaca_state.activeKey,
                    input_state: state.alpaca_state.input_state,
                    analysis_state:state.alpaca_state.analysis_state,
                    mod_state : state.alpaca_state.mod_state,
                    network_state : state.alpaca_state.network_state,
                    vis_state:{
                        vis_tab_disabled:state.alpaca_state.vis_state.vis_tab_disabled,
                        panda_results_table_data_full:state.alpaca_state.vis_state.panda_results_table_data_full,
                        panda_results_table_data_trimmed:action.panda_results_table_data_trimmed,
                        table_type:state.alpaca_state.vis_state.table_type,
                        network_data: state.alpaca_state.vis_state.network_data},
                target_state: state.alpaca_state.target_state,
                gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
                    download_state:state.alpaca_state.download_state} 
                };
            //console.log("#############state##########################");
            //console.log(new_alpaca_state33_2);
            return new_alpaca_state33_2;
            break
        case 'SET_ALPACA_VIS_STATE_NETWORK_DATA':
            const new_alpaca_state34 = { ...state, 
                alpaca_state: {
            numAnalysis: state.alpaca_state.numAnalysis,
            activeKey: state.alpaca_state.activeKey,
                input_state: state.alpaca_state.input_state,
                analysis_state:state.alpaca_state.analysis_state,
                mod_state : state.alpaca_state.mod_state,
                network_state : state.alpaca_state.network_state,
                vis_state:{
                    vis_tab_disabled:state.alpaca_state.vis_state.vis_tab_disabled,
                    panda_results_table_data_full:action.panda_results_table_data_full,
                    panda_results_table_data_trimmed:state.alpaca_state.vis_state.panda_results_table_data_trimmed,
                    table_type:state.alpaca_state.vis_state.table_type,
                    network_data: action.network_data },
            target_state: state.alpaca_state.target_state,
            gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
                download_state:state.alpaca_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_alpaca_state34);
            return new_alpaca_state34;
            break
        case 'SET_ALPACA_TARGET_STATE':
        const new_alpaca_state404 = { ...state, 
            alpaca_state: {
                numAnalysis: state.alpaca_state.numAnalysis,
                activeKey: state.alpaca_state.activeKey,
                input_state: state.alpaca_state.input_state,
                analysis_state:state.alpaca_state.analysis_state,
                mod_state : state.alpaca_state.mod_state,
                network_state : state.alpaca_state.network_state,
                vis_state:state.alpaca_state.vis_state,
                target_state: action.target_state,
                gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
                download_state:state.alpaca_state.download_state
            } 
        };
        //console.log("#############state##########################");
        //console.log(new_alpaca_state404);
        return new_alpaca_state404;
        break
        case 'SET_ALPACA_MOD_STATE':
        const new_alpaca_state41234 = { ...state, 
            alpaca_state: {
                numAnalysis: state.alpaca_state.numAnalysis,
                activeKey: state.alpaca_state.activeKey,
                input_state: state.alpaca_state.input_state,
                analysis_state:state.alpaca_state.analysis_state,
                mod_state: action.mod_state,
                network_state : state.alpaca_state.network_state,
                vis_state:state.alpaca_state.vis_state,
                target_state: state.alpaca_state.target_state,
                gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
                download_state: state.alpaca_state.download_state
            } 
        };
        //console.log("#############state##########################");
        //console.log(new_alpaca_state4123);
        return new_alpaca_state41234;
        break
        case 'SET_ALPACA_NETWORK_STATE':
        const new_alpaca_state412345 = { ...state, 
            alpaca_state: {
                numAnalysis: state.alpaca_state.numAnalysis,
                activeKey: state.alpaca_state.activeKey,
                input_state: state.alpaca_state.input_state,
                analysis_state:state.alpaca_state.analysis_state,
                mod_state: state.alpaca_statemod_state,
                network_state : action.network_state,
                vis_state:state.alpaca_state.vis_state,
                target_state: state.alpaca_state.target_state,
                gene_enrichment_state :state.alpaca_state.gene_enrichment_state,
                download_state: state.alpaca_state.download_state
            } 
        };
        //console.log("#############state##########################");
        //console.log(new_alpaca_state4123);
        return new_alpaca_state412345;
        break
        case 'SET_ALPACA_GENE_ENRICHMENT_STATE':
        const new_alpaca_state4123 = { ...state, 
            alpaca_state: {
                numAnalysis: state.alpaca_state.numAnalysis,
                activeKey: state.alpaca_state.activeKey,
                input_state: state.alpaca_state.input_state,
                analysis_state:state.alpaca_state.analysis_state,
                mod_state : state.alpaca_state.mod_state,
                network_state : state.alpaca_state.network_state,
                vis_state:state.alpaca_state.vis_state,
                target_state: state.alpaca_state.target_state,
                gene_enrichment_state : action.gene_enrichment_state,
                download_state: state.alpaca_state.download_state
            } 
        };
        //console.log("#############state##########################");
        //console.log(new_alpaca_state4123);
        return new_alpaca_state4123;
        break

        case 'SET_ALPACA_DOWN_STATE':
            const new_alpaca_state4 = { ...state, 
                alpaca_state: {
                numAnalysis: state.alpaca_state.numAnalysis,
                activeKey: state.alpaca_state.activeKey,
                    input_state: state.alpaca_state.input_state,
                    analysis_state:state.alpaca_state.analysis_state,
                    mod_state : state.alpaca_state.mod_state,
                    network_state : state.alpaca_state.network_state,
                    vis_state:state.alpaca_state.vis_state,
                    target_state: state.alpaca_state.target_state,
                    gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
                    download_state:action.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_alpaca_state4);
            return new_alpaca_state4;
            break
         
       
        case 'SET_ALPACA_ACTIVE_KEY':
                const new_state_alpaca_activeKey = { ...state,
                    alpaca_state: {
                        numAnalysis:state.alpaca_state.numAnalysis,
                        activeKey: action.activeKey,
                        input_state: state.alpaca_state.input_state,
                        analysis_state:state.alpaca_state.analysis_state,
                        mod_state : state.alpaca_state.mod_state,
                        network_state : state.alpaca_state.network_state,
                        vis_state:state.alpaca_state.vis_state,
                        target_state: state.alpaca_state.target_state,
                        gene_enrichment_state : state.alpaca_state.gene_enrichment_state,
                        download_state:state.alpaca_state.download_state
                        } 
                    };
                //console.log("#############state##########################");
                //console.log(new_state_lioness_activeKey);
                return new_state_alpaca_activeKey;
                break
       
         //############################ ALPACA STATES & ACTION END ########################################

         //############################ CRANE STATES & ACTION START ###################################
         case 'SET_CRANE_INPUT_STATE':
            const new_crane_state1  =  { ...state,
            
                crane_state: {
                numAnalysis: state.crane_state.numAnalysis,
                activeKey: state.crane_state.activeKey,
                    input_state: action.input_state,
                    analysis_state:state.crane_state.analysis_state,
                    vis_state:state.crane_state.vis_state,
                    mod_state : state.crane_state.mod_state,
                    network_state : state.crane_state.network_state,
                    target_state: state.crane_state.target_state,
                    gene_enrichment_state : state.crane_state.gene_enrichment_state,
                    download_state:state.crane_state.download_state
                    
                } 
            };
            // console.log("#############state##########################");
            // console.log(new_crane_state1);
            return new_crane_state1;
            break
        case 'SET_CRANE_INPUT_STATE_UID':
        const new_crane_state_uid  =  { ...state,
        
        crane_state: {
            numAnalysis: state.crane_state.numAnalysis,
            activeKey: state.crane_state.activeKey,
            input_state: {
                submit_disabled: state.crane_state.input_state.submit_disabled,
                input_files: state.crane_state.input_state.input_files,
                uid: action.uid
            },
            analysis_state:state.crane_state.analysis_state,
            mod_state : state.crane_state.mod_state,
            network_state : state.crane_state.network_state,
            vis_state:state.crane_state.vis_state,
            target_state: state.crane_state.target_state,
            gene_enrichment_state : state.crane_state.gene_enrichment_state,
            download_state:state.crane_state.download_state
            
        } 
        };
        //console.log("#############state##########################");
        //console.log(new_crane_state_uid);
        return new_crane_state_uid;
        break
        case 'SET_CRANE_NUM_ANALYSIS':
        const new_crane_state1234  =  { ...state,
        
        crane_state: {
            numAnalysis: action.numAnalysis,
            activeKey: state.crane_state.activeKey,
            input_state: state.crane_state.input_state,
            analysis_state:state.crane_state.analysis_state,
            mod_state : state.crane_state.mod_state,
            network_state : state.crane_state.network_state,
            vis_state:state.crane_state.vis_state,
            target_state: state.crane_state.target_state,
            gene_enrichment_state : state.crane_state.gene_enrichment_state,
            download_state:state.crane_state.download_state
            
        } 
        };
        //console.log("#############state##########################");
        //console.log(new_crane_state1234);
        return new_crane_state1234;
        break
        case 'SET_CRANE_ANALYSIS_STATE':
            const new_crane_state2 = { ...state,
            
                crane_state: {
                numAnalysis: state.crane_state.numAnalysis,
                activeKey: state.crane_state.activeKey,
                    input_state: state.crane_state.input_state,
                    analysis_state:action.analysis_state,
                    mod_state : state.crane_state.mod_state,
                    network_state : state.crane_state.network_state,
                    vis_state:state.crane_state.vis_state,
                    target_state: state.crane_state.target_state,
                    gene_enrichment_state : state.crane_state.gene_enrichment_state,
                    download_state:state.crane_state.download_state
                    } 
                };
            //console.log("#############state##########################");
            //console.log(new_crane_state2);
            return new_crane_state2;
            break
        case 'SET_CRANE_VIS_STATE':
            const new_crane_state3 = { ...state, 
                crane_state: {
                numAnalysis: state.crane_state.numAnalysis,
                activeKey: state.crane_state.activeKey, 
                input_state: state.crane_state.input_state,
                    analysis_state:state.crane_state.analysis_state,
                    vis_state:action.vis_state,
                    mod_state : state.crane_state.mod_state,
                    network_state : state.crane_state.network_state,
                    target_state: state.crane_state.target_state,
                    gene_enrichment_state : state.crane_state.gene_enrichment_state,
                    download_state:state.crane_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_crane_state3);
            return new_crane_state3;
            break
        case 'SET_CRANE_VIS_STATE_TAB_DISABLED':
            const new_crane_state31_tab = { ...state, 
                crane_state: {
            numAnalysis: state.crane_state.numAnalysis,
            activeKey: state.crane_state.activeKey,
                input_state: state.crane_state.input_state,
                analysis_state:state.crane_state.analysis_state,
                mod_state : state.crane_state.mod_state,
                network_state : state.crane_state.network_state,
                vis_state:{
                    vis_tab_disabled:action.vis_tab_disabled,
                    panda_results_table_data_full:state.crane_state.vis_state.panda_results_table_data_full,
                    panda_results_table_data_trimmed:state.crane_state.vis_state.panda_results_table_data_trimmed,
                    table_type: state.crane_state.vis_state.table_type,
                    network_data: state.crane_state.vis_state.network_data
                },
                target_state: state.crane_state.target_state,
                gene_enrichment_state : state.crane_state.gene_enrichment_state,
                download_state:state.crane_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_crane_state31_tab);
            return new_crane_state31_tab;
            break

        case 'SET_CRANE_VIS_STATE_TABLE_TYPE':
            const new_crane_state31 = { ...state, 
                crane_state: {
                numAnalysis: state.crane_state.numAnalysis,
                activeKey: state.crane_state.activeKey,
                input_state: state.crane_state.input_state,
                analysis_state:state.crane_state.analysis_state,
                mod_state : state.crane_state.mod_state,
                network_state : state.crane_state.network_state,
                vis_state:{
                    vis_tab_disabled:state.crane_state.vis_state.vis_tab_disabled,
                    panda_results_table_data_full:action.panda_results_table_data_full,
                    panda_results_table_data_trimmed:state.crane_state.vis_state.panda_results_table_data_trimmed,
                    table_type:action.table_type,
                    network_data: state.crane_state.vis_state.network_data
                },
                target_state: state.crane_state.target_state,
                gene_enrichment_state : state.crane_state.gene_enrichment_state,
                download_state:state.crane_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_crane_state31);
            return new_crane_state31;
            break
        case 'SET_CRANE_VIS_STATE_TABLE_DATA_FULL':
            const new_crane_state32 = { ...state, 
                crane_state: {
                numAnalysis: state.crane_state.numAnalysis,
                activeKey: state.crane_state.activeKey,
                    input_state: state.crane_state.input_state,
                    analysis_state:state.crane_state.analysis_state,
                    mod_state : state.crane_state.mod_state,
                    network_state : state.crane_state.network_state,
                    vis_state:{
                        vis_tab_disabled:state.crane_state.vis_state.vis_tab_disabled,
                        panda_results_table_data_full:action.panda_results_table_data_full,
                        panda_results_table_data_trimmed:state.crane_state.vis_state.panda_results_table_data_trimmed,
                        table_type:state.crane_state.vis_state.table_type,
                        network_data: state.crane_state.vis_state.network_data
                    },
                    target_state: state.crane_state.target_state,
                    gene_enrichment_state : state.crane_state.gene_enrichment_state,
                    download_state:state.crane_state.download_state
                    } 
                };
            //console.log("#############state##########################");
            //console.log(new_crane_state32);
            return new_crane_state32;
            break

        case 'SET_CRANE_VIS_STATE_TABLE_DATA_TRIMMED':
            const new_crane_state33_2 = { ...state, 
                crane_state: {
                numAnalysis: state.crane_state.numAnalysis,
                activeKey: state.crane_state.activeKey,
                    input_state: state.crane_state.input_state,
                    analysis_state:state.crane_state.analysis_state,
                    mod_state : state.crane_state.mod_state,
                    network_state : state.crane_state.network_state,
                    vis_state:{
                        vis_tab_disabled:state.crane_state.vis_state.vis_tab_disabled,
                        panda_results_table_data_full:state.crane_state.vis_state.panda_results_table_data_full,
                        panda_results_table_data_trimmed:action.panda_results_table_data_trimmed,
                        table_type:state.crane_state.vis_state.table_type,
                        network_data: state.crane_state.vis_state.network_data},
                target_state: state.crane_state.target_state,
                gene_enrichment_state : state.crane_state.gene_enrichment_state,
                    download_state:state.crane_state.download_state} 
                };
            //console.log("#############state##########################");
            //console.log(new_crane_state33_2);
            return new_crane_state33_2;
            break
        case 'SET_CRANE_VIS_STATE_NETWORK_DATA':
            const new_crane_state34 = { ...state, 
                crane_state: {
            numAnalysis: state.crane_state.numAnalysis,
            activeKey: state.crane_state.activeKey,
                input_state: state.crane_state.input_state,
                analysis_state:state.crane_state.analysis_state,
                mod_state : state.crane_state.mod_state,
                network_state : state.crane_state.network_state,
                vis_state:{
                    vis_tab_disabled:state.crane_state.vis_state.vis_tab_disabled,
                    panda_results_table_data_full:action.panda_results_table_data_full,
                    panda_results_table_data_trimmed:state.crane_state.vis_state.panda_results_table_data_trimmed,
                    table_type:state.crane_state.vis_state.table_type,
                    network_data: action.network_data },
            target_state: state.crane_state.target_state,
            gene_enrichment_state : state.crane_state.gene_enrichment_state,
                download_state:state.crane_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_crane_state34);
            return new_crane_state34;
            break
        case 'SET_CRANE_TARGET_STATE':
        const new_crane_state404 = { ...state, 
            crane_state: {
                numAnalysis: state.crane_state.numAnalysis,
                activeKey: state.crane_state.activeKey,
                input_state: state.crane_state.input_state,
                analysis_state:state.crane_state.analysis_state,
                mod_state : state.crane_state.mod_state,
                network_state : state.crane_state.network_state,
                vis_state:state.crane_state.vis_state,
                target_state: action.target_state,
                gene_enrichment_state : state.crane_state.gene_enrichment_state,
                download_state:state.crane_state.download_state
            } 
        };
        //console.log("#############state##########################");
        //console.log(new_crane_state404);
        return new_crane_state404;
        break
        case 'SET_CRANE_MOD_STATE':
        const new_crane_state41234 = { ...state, 
            crane_state: {
                numAnalysis: state.crane_state.numAnalysis,
                activeKey: state.crane_state.activeKey,
                input_state: state.crane_state.input_state,
                analysis_state:state.crane_state.analysis_state,
                mod_state: action.mod_state,
                network_state : state.crane_state.network_state,
                vis_state:state.crane_state.vis_state,
                target_state: state.crane_state.target_state,
                gene_enrichment_state : state.crane_state.gene_enrichment_state,
                download_state: state.crane_state.download_state
            } 
        };
        //console.log("#############state##########################");
        //console.log(new_crane_state4123);
        return new_crane_state41234;
        break
        case 'SET_CRANE_NETWORK_STATE':
        const new_crane_state412345 = { ...state, 
            crane_state: {
                numAnalysis: state.crane_state.numAnalysis,
                activeKey: state.crane_state.activeKey,
                input_state: state.crane_state.input_state,
                analysis_state:state.crane_state.analysis_state,
                mod_state: state.crane_statemod_state,
                network_state : action.network_state,
                vis_state:state.crane_state.vis_state,
                target_state: state.crane_state.target_state,
                gene_enrichment_state :state.crane_state.gene_enrichment_state,
                download_state: state.crane_state.download_state
            } 
        };
        //console.log("#############state##########################");
        //console.log(new_crane_state4123);
        return new_crane_state412345;
        break
        case 'SET_CRANE_GENE_ENRICHMENT_STATE':
        const new_crane_state4123 = { ...state, 
            crane_state: {
                numAnalysis: state.crane_state.numAnalysis,
                activeKey: state.crane_state.activeKey,
                input_state: state.crane_state.input_state,
                analysis_state:state.crane_state.analysis_state,
                mod_state : state.crane_state.mod_state,
                network_state : state.crane_state.network_state,
                vis_state:state.crane_state.vis_state,
                target_state: state.crane_state.target_state,
                gene_enrichment_state : action.gene_enrichment_state,
                download_state: state.crane_state.download_state
            } 
        };
        //console.log("#############state##########################");
        //console.log(new_crane_state4123);
        return new_crane_state4123;
        break

        case 'SET_CRANE_DOWN_STATE':
            const new_crane_state4 = { ...state, 
                crane_state: {
                numAnalysis: state.crane_state.numAnalysis,
                activeKey: state.crane_state.activeKey,
                    input_state: state.crane_state.input_state,
                    analysis_state:state.crane_state.analysis_state,
                    mod_state : state.crane_state.mod_state,
                    network_state : state.crane_state.network_state,
                    vis_state:state.crane_state.vis_state,
                    target_state: state.crane_state.target_state,
                    gene_enrichment_state : state.crane_state.gene_enrichment_state,
                    download_state:action.download_state
                } 
            };
            console.log("#############state##########################");
            console.log(new_crane_state4);
            return new_crane_state4;
            break
         
       
        case 'SET_CRANE_ACTIVE_KEY':
                const new_state_crane_activeKey = { ...state,
                    crane_state: {
                        numAnalysis:state.crane_state.numAnalysis,
                        activeKey: action.activeKey,
                        input_state: state.crane_state.input_state,
                        analysis_state:state.crane_state.analysis_state,
                        mod_state : state.crane_state.mod_state,
                        network_state : state.crane_state.network_state,
                        vis_state:state.crane_state.vis_state,
                        target_state: state.crane_state.target_state,
                        gene_enrichment_state : state.crane_state.gene_enrichment_state,
                        download_state:state.crane_state.download_state
                        } 
                    };
                //console.log("#############state##########################");
                //console.log(new_state_lioness_activeKey);
                return new_state_crane_activeKey;
                break
       
         //############################ CRANE  STATES & ACTION END ########################################

         //############################ MONSTER STATES & ACTION START ###################################
         case 'SET_MONSTER_INPUT_STATE':
            const new_monster_state1  =  { ...state,
            
                monster_state: {
                numAnalysis: state.monster_state.numAnalysis,
                activeKey: state.monster_state.activeKey,
                    input_state: action.input_state,
                    analysis_state:state.monster_state.analysis_state,
                    vis_state:state.monster_state.vis_state,
                    mod_state : state.monster_state.mod_state,
                    network_state : state.monster_state.network_state,
                    target_state: state.monster_state.target_state,
                    gene_enrichment_state : state.monster_state.gene_enrichment_state,
                    download_state:state.monster_state.download_state
                    
                } 
            };
            // console.log("#############state##########################");
            // console.log(new_monster_state1);
            return new_monster_state1;
            break
        case 'SET_MONSTER_INPUT_STATE_UID':
        const new_monster_state_uid  =  { ...state,
        
        monster_state: {
            numAnalysis: state.monster_state.numAnalysis,
            activeKey: state.monster_state.activeKey,
            input_state: {
                submit_disabled: state.monster_state.input_state.submit_disabled,
                input_files: state.monster_state.input_state.input_files,
                uid: action.uid
            },
            analysis_state:state.monster_state.analysis_state,
            mod_state : state.monster_state.mod_state,
            network_state : state.monster_state.network_state,
            vis_state:state.monster_state.vis_state,
            target_state: state.monster_state.target_state,
            gene_enrichment_state : state.monster_state.gene_enrichment_state,
            download_state:state.monster_state.download_state
            
        } 
        };
        //console.log("#############state##########################");
        //console.log(new_monster_state_uid);
        return new_monster_state_uid;
        break
        case 'SET_MONSTER_NUM_ANALYSIS':
        const new_monster_state1234  =  { ...state,
        
        monster_state: {
            numAnalysis: action.numAnalysis,
            activeKey: state.monster_state.activeKey,
            input_state: state.monster_state.input_state,
            analysis_state:state.monster_state.analysis_state,
            mod_state : state.monster_state.mod_state,
            network_state : state.monster_state.network_state,
            vis_state:state.monster_state.vis_state,
            target_state: state.monster_state.target_state,
            gene_enrichment_state : state.monster_state.gene_enrichment_state,
            download_state:state.monster_state.download_state
            
        } 
        };
        //console.log("#############state##########################");
        //console.log(new_monster_state1234);
        return new_monster_state1234;
        break
        case 'SET_MONSTER_ANALYSIS_STATE':
            const new_monster_state2 = { ...state,
            
                monster_state: {
                numAnalysis: state.monster_state.numAnalysis,
                activeKey: state.monster_state.activeKey,
                    input_state: state.monster_state.input_state,
                    analysis_state:action.analysis_state,
                    mod_state : state.monster_state.mod_state,
                    network_state : state.monster_state.network_state,
                    vis_state:state.monster_state.vis_state,
                    target_state: state.monster_state.target_state,
                    gene_enrichment_state : state.monster_state.gene_enrichment_state,
                    download_state:state.monster_state.download_state
                    } 
                };
            //console.log("#############state##########################");
            //console.log(new_monster_state2);
            return new_monster_state2;
            break
        case 'SET_MONSTER_VIS_STATE':
            const new_monster_state3 = { ...state, 
                monster_state: {
                numAnalysis: state.monster_state.numAnalysis,
                activeKey: state.monster_state.activeKey, 
                input_state: state.monster_state.input_state,
                    analysis_state:state.monster_state.analysis_state,
                    vis_state:action.vis_state,
                    mod_state : state.monster_state.mod_state,
                    network_state : state.monster_state.network_state,
                    target_state: state.monster_state.target_state,
                    gene_enrichment_state : state.monster_state.gene_enrichment_state,
                    download_state:state.monster_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_monster_state3);
            return new_monster_state3;
            break
        case 'SET_MONSTER_VIS_STATE_TAB_DISABLED':
            const new_monster_state31_tab = { ...state, 
                monster_state: {
            numAnalysis: state.monster_state.numAnalysis,
            activeKey: state.monster_state.activeKey,
                input_state: state.monster_state.input_state,
                analysis_state:state.monster_state.analysis_state,
                mod_state : state.monster_state.mod_state,
                network_state : state.monster_state.network_state,
                vis_state:{
                    vis_tab_disabled:action.vis_tab_disabled,
                    panda_results_table_data_full:state.monster_state.vis_state.panda_results_table_data_full,
                    panda_results_table_data_trimmed:state.monster_state.vis_state.panda_results_table_data_trimmed,
                    table_type: state.monster_state.vis_state.table_type,
                    network_data: state.monster_state.vis_state.network_data
                },
                target_state: state.monster_state.target_state,
                gene_enrichment_state : state.monster_state.gene_enrichment_state,
                download_state:state.monster_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_monster_state31_tab);
            return new_monster_state31_tab;
            break

        case 'SET_MONSTER_VIS_STATE_TABLE_TYPE':
            const new_monster_state31 = { ...state, 
                monster_state: {
                numAnalysis: state.monster_state.numAnalysis,
                activeKey: state.monster_state.activeKey,
                input_state: state.monster_state.input_state,
                analysis_state:state.monster_state.analysis_state,
                mod_state : state.monster_state.mod_state,
                network_state : state.monster_state.network_state,
                vis_state:{
                    vis_tab_disabled:state.monster_state.vis_state.vis_tab_disabled,
                    panda_results_table_data_full:action.panda_results_table_data_full,
                    panda_results_table_data_trimmed:state.monster_state.vis_state.panda_results_table_data_trimmed,
                    table_type:action.table_type,
                    network_data: state.monster_state.vis_state.network_data
                },
                target_state: state.monster_state.target_state,
                gene_enrichment_state : state.monster_state.gene_enrichment_state,
                download_state:state.monster_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_monster_state31);
            return new_monster_state31;
            break
        case 'SET_MONSTER_VIS_STATE_TABLE_DATA_FULL':
            const new_monster_state32 = { ...state, 
                monster_state: {
                numAnalysis: state.monster_state.numAnalysis,
                activeKey: state.monster_state.activeKey,
                    input_state: state.monster_state.input_state,
                    analysis_state:state.monster_state.analysis_state,
                    mod_state : state.monster_state.mod_state,
                    network_state : state.monster_state.network_state,
                    vis_state:{
                        vis_tab_disabled:state.monster_state.vis_state.vis_tab_disabled,
                        panda_results_table_data_full:action.panda_results_table_data_full,
                        panda_results_table_data_trimmed:state.monster_state.vis_state.panda_results_table_data_trimmed,
                        table_type:state.monster_state.vis_state.table_type,
                        network_data: state.monster_state.vis_state.network_data
                    },
                    target_state: state.monster_state.target_state,
                    gene_enrichment_state : state.monster_state.gene_enrichment_state,
                    download_state:state.monster_state.download_state
                    } 
                };
            //console.log("#############state##########################");
            //console.log(new_monster_state32);
            return new_monster_state32;
            break

        case 'SET_MONSTER_VIS_STATE_TABLE_DATA_TRIMMED':
            const new_monster_state33_2 = { ...state, 
                monster_state: {
                numAnalysis: state.monster_state.numAnalysis,
                activeKey: state.monster_state.activeKey,
                    input_state: state.monster_state.input_state,
                    analysis_state:state.monster_state.analysis_state,
                    mod_state : state.monster_state.mod_state,
                    network_state : state.monster_state.network_state,
                    vis_state:{
                        vis_tab_disabled:state.monster_state.vis_state.vis_tab_disabled,
                        panda_results_table_data_full:state.monster_state.vis_state.panda_results_table_data_full,
                        panda_results_table_data_trimmed:action.panda_results_table_data_trimmed,
                        table_type:state.monster_state.vis_state.table_type,
                        network_data: state.monster_state.vis_state.network_data},
                target_state: state.monster_state.target_state,
                gene_enrichment_state : state.monster_state.gene_enrichment_state,
                    download_state:state.monster_state.download_state} 
                };
            //console.log("#############state##########################");
            //console.log(new_monster_state33_2);
            return new_monster_state33_2;
            break
        case 'SET_MONSTER_VIS_STATE_NETWORK_DATA':
            const new_monster_state34 = { ...state, 
                monster_state: {
            numAnalysis: state.monster_state.numAnalysis,
            activeKey: state.monster_state.activeKey,
                input_state: state.monster_state.input_state,
                analysis_state:state.monster_state.analysis_state,
                mod_state : state.monster_state.mod_state,
                network_state : state.monster_state.network_state,
                vis_state:{
                    vis_tab_disabled:state.monster_state.vis_state.vis_tab_disabled,
                    panda_results_table_data_full:action.panda_results_table_data_full,
                    panda_results_table_data_trimmed:state.monster_state.vis_state.panda_results_table_data_trimmed,
                    table_type:state.monster_state.vis_state.table_type,
                    network_data: action.network_data },
            target_state: state.monster_state.target_state,
            gene_enrichment_state : state.monster_state.gene_enrichment_state,
                download_state:state.monster_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_monster_state34);
            return new_monster_state34;
            break
        case 'SET_MONSTER_TARGET_STATE':
        const new_monster_state404 = { ...state, 
            monster_state: {
                numAnalysis: state.monster_state.numAnalysis,
                activeKey: state.monster_state.activeKey,
                input_state: state.monster_state.input_state,
                analysis_state:state.monster_state.analysis_state,
                mod_state : state.monster_state.mod_state,
                network_state : state.monster_state.network_state,
                vis_state:state.monster_state.vis_state,
                target_state: action.target_state,
                gene_enrichment_state : state.monster_state.gene_enrichment_state,
                download_state:state.monster_state.download_state
            } 
        };
        //console.log("#############state##########################");
        //console.log(new_monster_state404);
        return new_monster_state404;
        break
        case 'SET_MONSTER_MOD_STATE':
        const new_monster_state41234 = { ...state, 
            monster_state: {
                numAnalysis: state.monster_state.numAnalysis,
                activeKey: state.monster_state.activeKey,
                input_state: state.monster_state.input_state,
                analysis_state:state.monster_state.analysis_state,
                mod_state: action.mod_state,
                network_state : state.monster_state.network_state,
                vis_state:state.monster_state.vis_state,
                target_state: state.monster_state.target_state,
                gene_enrichment_state : state.monster_state.gene_enrichment_state,
                download_state: state.monster_state.download_state
            } 
        };
        //console.log("#############state##########################");
        //console.log(new_monster_state4123);
        return new_monster_state41234;
        break
        case 'SET_MONSTER_NETWORK_STATE':
        const new_monster_state412345 = { ...state, 
            monster_state: {
                numAnalysis: state.monster_state.numAnalysis,
                activeKey: state.monster_state.activeKey,
                input_state: state.monster_state.input_state,
                analysis_state:state.monster_state.analysis_state,
                mod_state: state.monster_statemod_state,
                network_state : action.network_state,
                vis_state:state.monster_state.vis_state,
                target_state: state.monster_state.target_state,
                gene_enrichment_state :state.monster_state.gene_enrichment_state,
                download_state: state.monster_state.download_state
            } 
        };
        //console.log("#############state##########################");
        //console.log(new_monster_state4123);
        return new_monster_state412345;
        break
        case 'SET_MONSTER_GENE_ENRICHMENT_STATE':
        const new_monster_state4123 = { ...state, 
            monster_state: {
                numAnalysis: state.monster_state.numAnalysis,
                activeKey: state.monster_state.activeKey,
                input_state: state.monster_state.input_state,
                analysis_state:state.monster_state.analysis_state,
                mod_state : state.monster_state.mod_state,
                network_state : state.monster_state.network_state,
                vis_state:state.monster_state.vis_state,
                target_state: state.monster_state.target_state,
                gene_enrichment_state : action.gene_enrichment_state,
                download_state: state.monster_state.download_state
            } 
        };
        //console.log("#############state##########################");
        //console.log(new_monster_state4123);
        return new_monster_state4123;
        break

        case 'SET_MONSTER_DOWN_STATE':
            const new_monster_state4 = { ...state, 
                monster_state: {
                numAnalysis: state.monster_state.numAnalysis,
                activeKey: state.monster_state.activeKey,
                    input_state: state.monster_state.input_state,
                    analysis_state:state.monster_state.analysis_state,
                    mod_state : state.monster_state.mod_state,
                    network_state : state.monster_state.network_state,
                    vis_state:state.monster_state.vis_state,
                    target_state: state.monster_state.target_state,
                    gene_enrichment_state : state.monster_state.gene_enrichment_state,
                    download_state:action.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_monster_state4);
            return new_monster_state4;
            break
         
       
        case 'SET_MONSTER_ACTIVE_KEY':
                const new_state_monster_activeKey = { ...state,
                    monster_state: {
                        numAnalysis:state.monster_state.numAnalysis,
                        activeKey: action.activeKey,
                        input_state: state.monster_state.input_state,
                        analysis_state:state.monster_state.analysis_state,
                        mod_state : state.monster_state.mod_state,
                        network_state : state.monster_state.network_state,
                        vis_state:state.monster_state.vis_state,
                        target_state: state.monster_state.target_state,
                        gene_enrichment_state : state.monster_state.gene_enrichment_state,
                        download_state:state.monster_state.download_state
                        } 
                    };
                //console.log("#############state##########################");
                //console.log(new_state_lioness_activeKey);
                return new_state_monster_activeKey;
                break
       
         //############################ MONSTER STATES & ACTION END ########################################

            //############################ CONDOR STATES & ACTION START ###################################
            case 'SET_CONDOR_INPUT_STATE':
                const new_condor_state1  =  { ...state,
                
                    condor_state: {
                    numAnalysis: state.condor_state.numAnalysis,
                        input_state: action.input_state,
                        analysis_state:state.condor_state.analysis_state,
                        vis_state:state.condor_state.vis_state,
                        mod_state : state.condor_state.mod_state,
                        network_state : state.condor_state.network_state,
                        target_state: state.condor_state.target_state,
                        gene_enrichment_state : state.condor_state.gene_enrichment_state,
                        download_state:state.condor_state.download_state
                        
                    } 
                };
                //console.log("#############state##########################");
                //console.log(new_condor_state1);
                return new_condor_state1;
                break
            case 'SET_CONDOR_INPUT_STATE_UID':
            const new_condor_state_uid  =  { ...state,
            
            condor_state: {
                numAnalysis: state.condor_state.numAnalysis,
                input_state: {
                    submit_disabled: state.condor_state.input_state.submit_disabled,
                    input_files: state.condor_state.input_state.input_files,
                    uid: action.uid
                },
                analysis_state:state.condor_state.analysis_state,
                mod_state : state.condor_state.mod_state,
                network_state : state.condor_state.network_state,
                vis_state:state.condor_state.vis_state,
                target_state: state.condor_state.target_state,
                gene_enrichment_state : state.condor_state.gene_enrichment_state,
                download_state:state.condor_state.download_state
                
            } 
            };
            //console.log("#############state##########################");
            //console.log(new_condor_state_uid);
            return new_condor_state_uid;
            break
            case 'SET_CONDOR_NUM_ANALYSIS':
            const new_condor_state1234  =  { ...state,
            
            condor_state: {
                numAnalysis: action.numAnalysis,
                input_state: state.condor_state.input_state,
                analysis_state:state.condor_state.analysis_state,
                mod_state : state.condor_state.mod_state,
                network_state : state.condor_state.network_state,
                vis_state:state.condor_state.vis_state,
                target_state: state.condor_state.target_state,
                gene_enrichment_state : state.condor_state.gene_enrichment_state,
                download_state:state.condor_state.download_state
                
            } 
            };
            //console.log("#############state##########################");
            //console.log(new_condor_state1234);
            return new_condor_state1234;
            break
            case 'SET_CONDOR_ANALYSIS_STATE':
                const new_condor_state2 = { ...state,
                
                    condor_state: {
                    numAnalysis: state.condor_state.numAnalysis,
                        input_state: state.condor_state.input_state,
                        analysis_state:action.analysis_state,
                        mod_state : state.condor_state.mod_state,
                        network_state : state.condor_state.network_state,
                        vis_state:state.condor_state.vis_state,
                        target_state: state.condor_state.target_state,
                        gene_enrichment_state : state.condor_state.gene_enrichment_state,
                        download_state:state.condor_state.download_state
                        } 
                    };
                //console.log("#############state##########################");
                //console.log(new_condor_state2);
                return new_condor_state2;
                break
            case 'SET_CONDOR_VIS_STATE':
                const new_condor_state3 = { ...state, 
                    condor_state: {
                    numAnalysis: state.condor_state.numAnalysis, 
                    input_state: state.condor_state.input_state,
                        analysis_state:state.condor_state.analysis_state,
                        vis_state:action.vis_state,
                        mod_state : state.condor_state.mod_state,
                        network_state : state.condor_state.network_state,
                        target_state: state.condor_state.target_state,
                        gene_enrichment_state : state.condor_state.gene_enrichment_state,
                        download_state:state.condor_state.download_state
                    } 
                };
                //console.log("#############state##########################");
                //console.log(new_condor_state3);
                return new_condor_state3;
                break
            case 'SET_CONDOR_VIS_STATE_TAB_DISABLED':
                const new_condor_state31_tab = { ...state, 
                    condor_state: {
                numAnalysis: state.condor_state.numAnalysis,
                    input_state: state.condor_state.input_state,
                    analysis_state:state.condor_state.analysis_state,
                    mod_state : state.condor_state.mod_state,
                    network_state : state.condor_state.network_state,
                    vis_state:{
                        vis_tab_disabled:action.vis_tab_disabled,
                        panda_results_table_data_full:state.condor_state.vis_state.panda_results_table_data_full,
                        panda_results_table_data_trimmed:state.condor_state.vis_state.panda_results_table_data_trimmed,
                        table_type: state.condor_state.vis_state.table_type,
                        network_data: state.condor_state.vis_state.network_data
                    },
                    target_state: state.condor_state.target_state,
                    gene_enrichment_state : state.condor_state.gene_enrichment_state,
                    download_state:state.condor_state.download_state} 
                };
                //console.log("#############state##########################");
                //console.log(new_condor_state31_tab);
                return new_condor_state31_tab;
                break

            case 'SET_CONDOR_VIS_STATE_TABLE_TYPE':
                const new_condor_state31 = { ...state, 
                    condor_state: {
                    numAnalysis: state.condor_state.numAnalysis,
                    input_state: state.condor_state.input_state,
                    analysis_state:state.condor_state.analysis_state,
                    mod_state : state.condor_state.mod_state,
                    network_state : state.condor_state.network_state,
                    vis_state:{
                        vis_tab_disabled:state.condor_state.vis_state.vis_tab_disabled,
                        panda_results_table_data_full:action.panda_results_table_data_full,
                        panda_results_table_data_trimmed:state.condor_state.vis_state.panda_results_table_data_trimmed,
                        table_type:action.table_type,
                        network_data: state.condor_state.vis_state.network_data
                    },
                    target_state: state.condor_state.target_state,
                    gene_enrichment_state : state.condor_state.gene_enrichment_state,
                    download_state:state.condor_state.download_state} 
                };
                //console.log("#############state##########################");
                //console.log(new_condor_state31);
                return new_condor_state31;
                break
            case 'SET_CONDOR_VIS_STATE_TABLE_DATA_FULL':
                const new_condor_state32 = { ...state, 
                    condor_state: {
                    numAnalysis: state.condor_state.numAnalysis,
                        input_state: state.condor_state.input_state,
                        analysis_state:state.condor_state.analysis_state,
                        mod_state : state.condor_state.mod_state,
                        network_state : state.condor_state.network_state,
                        vis_state:{
                            vis_tab_disabled:state.condor_state.vis_state.vis_tab_disabled,
                            panda_results_table_data_full:action.panda_results_table_data_full,
                            panda_results_table_data_trimmed:state.condor_state.vis_state.panda_results_table_data_trimmed,
                            table_type:state.condor_state.vis_state.table_type,
                            network_data: state.condor_state.vis_state.network_data
                        },
                        target_state: state.condor_state.target_state,
                        gene_enrichment_state : state.condor_state.gene_enrichment_state,
                        download_state:state.condor_state.download_state
                        } 
                    };
                //console.log("#############state##########################");
                //console.log(new_condor_state32);
                return new_condor_state32;
                break

            case 'SET_CONDOR_VIS_STATE_TABLE_DATA_TRIMMED':
                const new_condor_state33_2 = { ...state, 
                    condor_state: {
                    numAnalysis: state.condor_state.numAnalysis,
                        input_state: state.condor_state.input_state,
                        analysis_state:state.condor_state.analysis_state,
                        mod_state : state.condor_state.mod_state,
                        network_state : state.condor_state.network_state,
                        vis_state:{
                            vis_tab_disabled:state.condor_state.vis_state.vis_tab_disabled,
                            panda_results_table_data_full:state.condor_state.vis_state.panda_results_table_data_full,
                            panda_results_table_data_trimmed:action.panda_results_table_data_trimmed,
                            table_type:state.condor_state.vis_state.table_type,
                            network_data: state.condor_state.vis_state.network_data},
                    target_state: state.condor_state.target_state,
                    gene_enrichment_state : state.condor_state.gene_enrichment_state,
                        download_state:state.condor_state.download_state} 
                    };
                //console.log("#############state##########################");
                //console.log(new_condor_state33_2);
                return new_condor_state33_2;
                break
            case 'SET_CONDOR_VIS_STATE_NETWORK_DATA':
                const new_condor_state34 = { ...state, 
                    condor_state: {
                numAnalysis: state.condor_state.numAnalysis,
                    input_state: state.condor_state.input_state,
                    analysis_state:state.condor_state.analysis_state,
                    mod_state : state.condor_state.mod_state,
                    network_state : state.condor_state.network_state,
                    vis_state:{
                        vis_tab_disabled:state.condor_state.vis_state.vis_tab_disabled,
                        panda_results_table_data_full:action.panda_results_table_data_full,
                        panda_results_table_data_trimmed:state.condor_state.vis_state.panda_results_table_data_trimmed,
                        table_type:state.condor_state.vis_state.table_type,
                        network_data: action.network_data },
                target_state: state.condor_state.target_state,
                gene_enrichment_state : state.condor_state.gene_enrichment_state,
                    download_state:state.condor_state.download_state} 
                };
                //console.log("#############state##########################");
                //console.log(new_condor_state34);
                return new_condor_state34;
                break
            case 'SET_CONDOR_TARGET_STATE':
            const new_condor_state404 = { ...state, 
                condor_state: {
                    numAnalysis: state.condor_state.numAnalysis,
                    input_state: state.condor_state.input_state,
                    analysis_state:state.condor_state.analysis_state,
                    mod_state : state.condor_state.mod_state,
                    network_state : state.condor_state.network_state,
                    vis_state:state.condor_state.vis_state,
                    target_state: action.target_state,
                    gene_enrichment_state : state.condor_state.gene_enrichment_state,
                    download_state:state.condor_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_condor_state404);
            return new_condor_state404;
            break
            case 'SET_CONDOR_MOD_STATE':
            const new_condor_state41234 = { ...state, 
                condor_state: {
                    numAnalysis: state.condor_state.numAnalysis,
                    input_state: state.condor_state.input_state,
                    analysis_state:state.condor_state.analysis_state,
                    mod_state: action.mod_state,
                    network_state : state.condor_state.network_state,
                    vis_state:state.condor_state.vis_state,
                    target_state: state.condor_state.target_state,
                    gene_enrichment_state : state.condor_state.gene_enrichment_state,
                    download_state: state.condor_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_condor_state4123);
            return new_condor_state41234;
            break
            case 'SET_CONDOR_NETWORK_STATE':
            const new_condor_state412345 = { ...state, 
                condor_state: {
                    numAnalysis: state.condor_state.numAnalysis,
                    input_state: state.condor_state.input_state,
                    analysis_state:state.condor_state.analysis_state,
                    mod_state: state.condor_statemod_state,
                    network_state : action.network_state,
                    vis_state:state.condor_state.vis_state,
                    target_state: state.condor_state.target_state,
                    gene_enrichment_state :state.condor_state.gene_enrichment_state,
                    download_state: state.condor_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_condor_state4123);
            return new_condor_state412345;
            break
            case 'SET_CONDOR_GENE_ENRICHMENT_STATE':
            const new_condor_state4123 = { ...state, 
                condor_state: {
                    numAnalysis: state.condor_state.numAnalysis,
                    input_state: state.condor_state.input_state,
                    analysis_state:state.condor_state.analysis_state,
                    mod_state : state.condor_state.mod_state,
                    network_state : state.condor_state.network_state,
                    vis_state:state.condor_state.vis_state,
                    target_state: state.condor_state.target_state,
                    gene_enrichment_state : action.gene_enrichment_state,
                    download_state: state.condor_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_condor_state4123);
            return new_condor_state4123;
            break

            case 'SET_CONDOR_DOWN_STATE':
                const new_condor_state4 = { ...state, 
                    condor_state: {
                    numAnalysis: state.condor_state.numAnalysis,
                        input_state: state.condor_state.input_state,
                        analysis_state:state.condor_state.analysis_state,
                        mod_state : state.condor_state.mod_state,
                        network_state : state.condor_state.network_state,
                        vis_state:state.condor_state.vis_state,
                        target_state: state.condor_state.target_state,
                        gene_enrichment_state : state.condor_state.gene_enrichment_state,
                        download_state:action.download_state
                    } 
                };
                //console.log("#############state##########################");
                //console.log(new_condor_state4);
                return new_condor_state4;
                break
            //############################ CONDOR STATES & ACTION END ########################################




         
          //############################ LIONESS STATES & ACTION START ###################################
          case 'SET_LIONESS_INPUT_STATE':
            const new_state_lioness1  =  { ...state, 
                lioness_state: {
                    activeKey: "Input",
                    numAnalysis: state.lioness_state.numAnalysis,
                    input_state: action.input_state,
                    analysis_state:state.lioness_state.analysis_state,
                    vis_state:state.lioness_state.vis_state,
                    target_state: state.lioness_state.target_state,
                    gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                    download_state:state.lioness_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_lioness1);
            return new_state_lioness1;
            break
        case 'SET_LIONESS_INPUT_STATE_UID':
            const new_state_lioness_uid  =  { ...state, 
                lioness_state: {
                    activeKey: "Input",
                    numAnalysis: state.lioness_state.numAnalysis,
                    input_state: {
                        submit_disabled : state.lioness_state.input_state.submit_disabled,
                        input_files: state.lioness_state.input_state.input_files,
                        input_mode: state.lioness_state.input_state.input_mode,
                        uid: action.uid,
                    },
                    analysis_state:state.lioness_state.analysis_state,
                    vis_state:state.lioness_state.vis_state,
                    target_state: state.lioness_state.target_state,
                    gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                    download_state:state.lioness_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_lioness_uid);
            return new_state_lioness_uid;
            break
        case 'SET_LIONESS_NUM_ANALYSIS':
            const new_state_lioness1234  =  { ...state, 
                lioness_state: {
                    activeKey: "Input",
                    numAnalysis: action.numAnalysis,
                    input_state: state.lioness_state.input_state,
                    analysis_state:state.lioness_state.analysis_state,
                    vis_state:state.lioness_state.vis_state,
                    target_state: state.lioness_state.target_state,
                    gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                    download_state:state.lioness_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_lioness1234);
            return new_state_lioness1234;
        break
        case 'SET_LIONESS_ANALYSIS_STATE':
            const new_state_lioness2 = { ...state, 
                lioness_state: {
                    numAnalysis: state.lioness_state.numAnalysis,
                    activeKey: state.lioness_state.activeKey,
                    input_state: state.lioness_state.input_state,
                    analysis_state:action.analysis_state,
                    vis_state:state.lioness_state.vis_state,
                    target_state: state.lioness_state.target_state,
                    gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                    download_state:state.lioness_state.download_state
                    } 
                };
            //console.log("#############state##########################");
            //console.log(new_state_lioness2);
            return new_state_lioness2;
            break
        case 'SET_LIONESS_ANALYSIS_STATE_LINKED_BY':
            const new_state_lioness21 = { ...state, 
                lioness_state: {
                    numAnalysis: state.lioness_state.numAnalysis,
                    activeKey: "Analysis",
                    input_state: state.lioness_state.input_state,
                    analysis_state:{
                        alpha: state.lioness_state.analysis_state.alpha,
                        mode: state.lioness_state.analysis_state.mode,
                        start: state.lioness_state.analysis_state.start ,
                        end: state.lioness_state.analysis_state.end,
                        max_sample_num : state.lioness_state.analysis_state.max_sample_num,
                        lioness_results_file : state.lioness_state.lioness_results_file,
                        analysis_tab_disabled: state.lioness_state.analysis_tab_disabled,
                        linkedBy: action.linkedBy,

                    },
                    vis_state:state.lioness_state.vis_state,
                    target_state: state.lioness_state.target_state,
                    gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                    download_state:state.lioness_state.download_state
                    } 
                };
            //console.log("#############state##########################");
            //console.log(new_state_lioness21);
            return new_state_lioness21;
            break
            case 'SET_LIONESS_ACTIVE_KEY':
                const new_state_lioness_activeKey = { ...state,
                    lioness_state: {
                        numAnalysis: state.lioness_state.numAnalysis,
                        activeKey: action.activeKey,
                        input_state: state.lioness_state.input_state,
                        analysis_state:state.lioness_state.analysis_state,
                        vis_state:state.lioness_state.vis_state,
                        target_state: state.lioness_state.target_state,
                        gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                        download_state:state.lioness_state.download_state
                        } 
                    };
                //console.log("#############state##########################");
                //console.log(new_state_lioness_activeKey);
                return new_state_lioness_activeKey;
                break
        case 'SET_LIONESS_VIS_STATE':
            const new_state_lioness3 = { ...state,
                
                lioness_state: {
                    numAnalysis: state.lioness_state.numAnalysis,
                    activeKey: "Input",
                    input_state: state.lioness_state.input_state,
                    analysis_state:state.lioness_state.analysis_state,
                    vis_state:action.vis_state,
                    target_state: state.lioness_state.target_state,
                    gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                    download_state:state.lioness_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_lioness3);
            return new_state_lioness3;
            break
        case 'SET_LIONESS_VIS_STATE_TAB_DISABLED':
            const new_state_lioness31_tab = { ...state,
                
                lioness_state: {
                numAnalysis: state.lioness_state.numAnalysis, 
                activeKey: "Input",
                input_state: state.lioness_state.input_state,
                analysis_state:state.lioness_state.analysis_state,
                vis_state:{
                    vis_tab_disabled:action.vis_tab_disabled,
                    lioness_results_table_data_full:state.lioness_state.vis_state.lioness_results_table_data_full,
                    lioness_results_table_data_trimmed:state.lioness_state.vis_state.lioness_results_table_data_trimmed,
                    table_type: state.lioness_state.vis_state.table_type,
                    network_data: state.lioness_state.vis_state.network_data
                },
                target_state: state.lioness_state.target_state,
                gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                download_state:state.lioness_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_state_lioness31_tab);
            return new_state_lioness31_tab;
            break
        
        case 'SET_LIONESS_VIS_STATE_TABLE_TYPE':
            const new_state_lioness31 = { ...state,

                lioness_state: {
                activeKey: "Input",
                numAnalysis: state.lioness_state.numAnalysis,
                input_state: state.lioness_state.input_state,
                analysis_state:state.lioness_state.analysis_state,
                vis_state:{
                    vis_tab_disabled:state.lioness_state.vis_state.vis_tab_disabled,
                    lioness_results_table_data_full:action.lioness_results_table_data_full,
                    lioness_results_table_data_trimmed:state.lioness_state.vis_state.lioness_results_table_data_trimmed,
                    table_type:action.table_type,
                    network_data: state.lioness_state.vis_state.network_data
                },
                target_state: state.lioness_state.target_state,
                gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                download_state:state.lioness_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_state_lioness31);
            return new_state_lioness31;
            break
        case 'SET_LIONESS_VIS_STATE_TABLE_DATA_FULL':
            const new_state_lioness32 = { ...state, 
                lioness_state: {
                    activeKey: "Input",
                    numAnalysis: state.lioness_state.numAnalysis,
                    input_state: state.lioness_state.input_state,
                    analysis_state:state.lioness_state.analysis_state,
                    vis_state:{
                        vis_tab_disabled:state.lioness_state.vis_state.vis_tab_disabled,
                        lioness_results_table_data_full:action.lioness_results_table_data_full,
                        lioness_results_table_data_trimmed:state.lioness_state.vis_state.lioness_results_table_data_trimmed,
                        table_type:state.lioness_state.vis_state.table_type,
                        network_data: state.lioness_state.vis_state.network_data
                    },
                    target_state: state.lioness_state.target_state,
                    gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                    download_state:state.lioness_state.download_state
                    } 
                };
            //console.log("#############state##########################");
            //console.log(new_state_lioness32);
            return new_state_lioness32;
            break
        
        case 'SET_LIONESS_VIS_STATE_TABLE_DATA_TRIMMED':
            const new_state_lioness33_2 = { ...state, 
                lioness_state: {
                    numAnalysis: state.lioness_state.numAnalysis,
                    activeKey: "Input",
                    input_state: state.lioness_state.input_state,
                    analysis_state:state.lioness_state.analysis_state,
                    vis_state:{
                        vis_tab_disabled:state.lioness_state.vis_state.vis_tab_disabled,
                        lioness_results_table_data_full:state.lioness_state.vis_state.lioness_results_table_data_full,
                        lioness_results_table_data_trimmed:action.lioness_results_table_data_trimmed,
                        table_type:state.lioness_state.vis_state.table_type,
                        network_data: state.lioness_state.vis_state.network_data},
                    target_state: state.lioness_state.target_state,
                    gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                    download_state:state.lioness_state.download_state} 
                };
            //console.log("#############state##########################");
            //console.log(new_state_lioness33_2);
            return new_state_lioness33_2;
            break
        case 'SET_LIONESS_VIS_STATE_NETWORK_DATA':
            const new_state_lioness34 = { ...state, 
                lioness_state: {
                numAnalysis: state.lioness_state.numAnalysis,
                activeKey: "Input",
                input_state: state.lioness_state.input_state,
                analysis_state:state.lioness_state.analysis_state,
                vis_state:{
                    vis_tab_disabled:state.lioness_state.vis_state.vis_tab_disabled,
                    lioness_results_table_data_full:action.lioness_results_table_data_full,
                    lioness_results_table_data_trimmed:state.lioness_state.vis_state.lioness_results_table_data_trimmed,
                    table_type:state.lioness_state.vis_state.table_type,
                    network_data: action.network_data },
                target_state: state.lioness_state.target_state,
                gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                download_state:state.lioness_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_state_lioness34);
            return new_state_lioness34;
            break
        case 'SET_LIONESS_TARGET_STATE':
            const new_state_lioness404 = { ...state, 
                lioness_state: {
                    numAnalysis: state.lioness_state.numAnalysis,
                    activeKey: "Input",
                    input_state: state.lioness_state.input_state,
                    analysis_state:state.lioness_state.analysis_state,
                    vis_state:state.lioness_state.vis_state,
                    target_state: action.target_state,
                    gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                    download_state: state.lioness_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_lioness404);
            return new_state_lioness404;
            break
            case 'SET_LIONESS_GENE_ENRICHMENT_STATE':
                const new_lioness_state31234 = { ...state, 
                    lioness_state: {
                        numAnalysis: state.lioness_state.numAnalysis,
                        activeKey: state.lioness_state.activeKey, 
                        input_state: state.lioness_state.input_state,
                        analysis_state:state.lioness_state.analysis_state,
                        vis_state:state.lioness_state.vis_state,
                        target_state:state.lioness_state.target_state,
                        gene_enrichment_state : action.gene_enrichment_state,
                        download_state:state.lioness_state.download_state
                    } 
                };
                //console.log("#############state##########################");
                //console.log(new_lioness_state31234);
                return new_lioness_state31234;
                break
        case 'SET_LIONESS_DOWN_STATE':
            const new_state_lioness4 = { ...state, 
                lioness_state: {
                    numAnalysis: state.lioness_state.numAnalysis,
                    activeKey: "Input",
                    input_state: state.lioness_state.input_state,
                    analysis_state:state.lioness_state.analysis_state,
                    vis_state:state.lioness_state.vis_state,
                    target_state: state.lioness_state.target_state,
                    gene_enrichment_state : state.lioness_state.gene_enrichment_state,
                    download_state:action.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_lioness4);
            return new_state_lioness4;
            break


           //############################ LIONESS STATES & ACTION END ###################################




         //############################ PUMA STATES & ACTION START ######################################
         case 'SET_PUMA_INPUT_STATE':
             const new_puma_state1  =  { ...state, 
                 puma_state: {
                    numAnalysis: state.lioness_state.numAnalysis,
                     activeKey: state.puma_state.activeKey,
                     input_state: action.input_state,
                     analysis_state:state.puma_state.analysis_state,
                     vis_state:state.puma_state.vis_state,
                     target_state: state.puma_state.target_state,
                     gene_enrichment_state : state.puma_state.gene_enrichment_state,
                     download_state:state.puma_state.download_state
                 } 
             };
             //console.log("#############state##########################");
             //console.log(new_puma_state1);
             return new_puma_state1;
             break
             case 'SET_PUMA_INPUT_STATE_UID':
                const new_puma_state_uid  =  { ...state, 
                    puma_state: {
                       numAnalysis: state.lioness_state.numAnalysis,
                        activeKey: state.puma_state.activeKey,
                        input_state: {
                            submit_disabled : state.puma_state.input_state.submit_disabled,
                            input_files: state.puma_state.input_state.input_files ,
                            uid: action.uid   
                        },
                        analysis_state:state.puma_state.analysis_state,
                        vis_state:state.puma_state.vis_state,
                        target_state: state.puma_state.target_state,
                        gene_enrichment_state : state.puma_state.gene_enrichment_state,
                        download_state:state.puma_state.download_state
                    } 
                };
                //console.log("#############state##########################");
                //console.log(new_puma_state_uid);
                return new_puma_state_uid;
                break
             case 'SET_PUMA_NUM_ANALYSIS':
                const new_puma_state1234  =  { ...state, 
                    puma_state: {
                       numAnalysis: action.numAnalysis,
                        activeKey: state.puma_state.activeKey,
                        input_state: state.puma_state.input_state,
                        analysis_state:state.puma_state.analysis_state,
                        vis_state:state.puma_state.vis_state,
                        target_state: state.puma_state.target_state,
                        gene_enrichment_state : state.puma_state.gene_enrichment_state,
                        download_state:state.puma_state.download_state
                    } 
                };
                //console.log("#############state##########################");
                //console.log(new_puma_state1234);
                return new_puma_state1234;
                break
         case 'SET_PUMA_ANALYSIS_STATE':
             const new_puma_state2 = { ...state, 
                 puma_state: {
                    numAnalysis: state.puma_state.numAnalysis,
                    activeKey: state.puma_state.activeKey,
                     input_state: state.puma_state.input_state,
                     analysis_state:action.analysis_state,
                     vis_state:state.puma_state.vis_state,
                     target_state: state.puma_state.target_state,
                     gene_enrichment_state : state.puma_state.gene_enrichment_state,
                     download_state:state.puma_state.download_state
                     } 
                 };
             //console.log("#############state##########################");
             //console.log(new_puma_state2);
             return new_puma_state2;
             break
             case 'SET_PUMA_ACTIVE_KEY':
                const new_state_puma_activeKey = { ...state, 
                    puma_state: {
                        numAnalysis: state.puma_state.numAnalysis,
                        activeKey: action.activeKey,
                        input_state: state.puma_state.input_state,
                        analysis_state:state.puma_state.analysis_state,
                        vis_state:state.puma_state.vis_state,
                        target_state: state.puma_state.target_state,
                        gene_enrichment_state : state.puma_state.gene_enrichment_state,
                        download_state:state.puma_state.download_state
                        } 
                    };
                //console.log("#############state##########################");
                //console.log(new_state_puma_activeKey);
                return new_state_puma_activeKey;
                break
             case 'SET_PUMA_VIS_STATE':
                 const new_puma_state3 = { ...state, 
                     puma_state: {
                        numAnalysis: state.puma_state.numAnalysis,
                        activeKey: state.puma_state.activeKey, 
                        input_state: state.puma_state.input_state,
                         analysis_state:state.puma_state.analysis_state,
                         vis_state:action.vis_state,
                         target_state: state.puma_state.target_state,
                         gene_enrichment_state : state.puma_state.gene_enrichment_state,
                         download_state:state.puma_state.download_state
                     } 
                 };
                 //console.log("#############state##########################");
                 //console.log(new_puma_state3);
                 return new_puma_state3;
                 break
                 
         case 'SET_PUMA_VIS_STATE_TAB_DISABLED':
             const new_state31_puma_tab = { ...state, 
                 puma_state: {
                    numAnalysis: state.puma_state.numAnalysis,
                activeKey: state.puma_state.activeKey,
                 input_state: state.puma_state.input_state,
                 analysis_state:state.puma_state.analysis_state,
                 vis_state:{
                     vis_tab_disabled:action.vis_tab_disabled,
                     puma_results_table_data_full:state.puma_state.vis_state.puma_results_table_data_full,
                     puma_results_table_data_trimmed:state.puma_state.vis_state.puma_results_table_data_trimmed,
                     table_type:state.puma_state.vis_state.table_type,
                     network_data: state.puma_state.vis_state.network_data
                 },
                 target_state: state.puma_state.target_state,
                 gene_enrichment_state : state.puma_state.gene_enrichment_state,
                 download_state:state.puma_state.download_state} 
             };
             //console.log("#############state##########################");
             //console.log(new_state31_puma_tab);
             return new_state31_puma_tab;
                     break
             
         case 'SET_PUMA_VIS_STATE_TABLE_TYPE':
             const new_puma_state31 = { ...state, 
                 puma_state: {
                    numAnalysis: state.puma_state.numAnalysis,
                    activeKey: state.puma_state.activeKey,
                 input_state: state.puma_state.input_state,
                 analysis_state:state.puma_state.analysis_state,
                 vis_state:{
                     vis_tab_disabled:state.puma_state.vis_state.vis_tab_disabled,
                     puma_results_table_data_full:action.puma_results_table_data_full,
                     puma_results_table_data_trimmed:state.puma_state.vis_state.puma_results_table_data_trimmed,
                     table_type:action.table_type,
                     adj_matrix: state.puma_state.vis_state.adj_matrix 
                 },
                 target_state: state.puma_state.target_state,
                 gene_enrichment_state : state.puma_state.gene_enrichment_state,
                 download_state:state.puma_state.download_state} 
             };
             //console.log("#############state##########################");
             //console.log(new_puma_state31);
             return new_puma_state31;
             break
         case 'SET_PUMA_VIS_STATE_TABLE_DATA_FULL':
             const new_puma_state32 = { ...state, 
                 puma_state: {
                    numAnalysis: state.puma_state.numAnalysis,
                    activeKey: state.puma_state.activeKey,
                     input_state: state.puma_state.input_state,
                     analysis_state:state.puma_state.analysis_state,
                     vis_state:{
                         vis_tab_disabled:state.puma_state.vis_state.vis_tab_disabled,
                         puma_results_table_data_full:action.puma_results_table_data_full,
                         puma_results_table_data_trimmed:state.puma_state.vis_state.puma_results_table_data_trimmed,
                         table_type:state.puma_state.vis_state.table_type,
                         adj_matrix: state.puma_state.vis_state.adj_matrix 
                     },
                     target_state: state.puma_state.target_state,
                     gene_enrichment_state : state.puma_state.gene_enrichment_state,
                     download_state:state.puma_state.download_state
                     } 
                 };
             //console.log("#############state##########################");
             //console.log(new_puma_state32);
             return new_puma_state32;
             break
         
         case 'SET_PUMA_VIS_STATE_TABLE_DATA_TRIMMED':
             const new_puma_state33 = { ...state, 
                 puma_state: {
                    numAnalysis: state.puma_state.numAnalysis,
                    activeKey: state.puma_state.activeKey,
                     input_state: state.puma_state.input_state,
                     analysis_state:state.puma_state.analysis_state,
                     vis_state:{
                         vis_tab_disabled:state.puma_state.vis_state.vis_tab_disabled,
                         puma_results_table_data_full:state.puma_state.vis_state.puma_results_table_data_full,
                         puma_results_table_data_trimmed:action.puma_results_table_data_trimmed,
                         table_type:state.puma_state.vis_state.table_type,
                         adj_matrix: state.puma_state.vis_state.adj_matrix },
                    target_state: state.puma_state.target_state,
                    gene_enrichment_state : state.puma_state.gene_enrichment_state,
                     download_state:state.puma_state.download_state} 
                 };
             //console.log("#############state##########################");
             //console.log(new_puma_state33);
             return new_puma_state33;
             break
         case 'SET_PUMA_VIS_STATE_ADJ_MATRIX':
             const new_puma_state34 = { ...state, 
                 puma_state: {
                    numAnalysis: state.puma_state.numAnalysis,
                    activeKey: state.puma_state.activeKey,
                 input_state: state.puma_state.input_state,
                 analysis_state:state.puma_state.analysis_state,
                 vis_state:{
                     vis_tab_disabled:state.puma_state.vis_state.vis_tab_disabled,
                     puma_results_table_data_full:action.puma_results_table_data_full,
                     puma_results_table_data_trimmed:state.puma_state.vis_state.puma_results_table_data_trimmed,
                     table_type:state.puma_state.vis_state.table_type,
                     adj_matrix: action.adj_matrix },
                target_state: state.puma_state.target_state,
                gene_enrichment_state : state.puma_state.gene_enrichment_state,
                 download_state:state.puma_state.download_state} 
             };
             //console.log("#############state##########################");
             //console.log(new_puma_state34);
             return new_puma_state34;
             break
             case 'SET_PUMA_VIS_STATE_NETWORK_DATA':
                const new_state_puma34 = { ...state, 
                    puma_state: {
                    numAnalysis: state.puma_state.numAnalysis,
                    activeKey: state.puma_state.activeKey,
                    input_state: state.puma_state.input_state,
                    analysis_state:state.puma_state.analysis_state,
                    vis_state:{
                        vis_tab_disabled:state.puma_state.vis_state.vis_tab_disabled,
                        lioness_results_table_data_full:action.puma_results_table_data_full,
                        lioness_results_table_data_trimmed:state.puma_state.vis_state.lioness_results_table_data_trimmed,
                        table_type:state.puma_state.vis_state.table_type,
                        network_data: action.network_data },
                    target_state: state.puma_state.target_state,
                    gene_enrichment_state : state.puma_state.gene_enrichment_state,
                    download_state:state.puma_state.download_state} 
                };
                //console.log("#############state##########################");
                //console.log(new_state_puma34);
                return new_state_puma34;
                break
            case 'SET_PUMA_TARGET_STATE':
            const new_puma_state3123 = { ...state, 
                puma_state: {
                    numAnalysis: state.puma_state.numAnalysis,
                    activeKey: state.puma_state.activeKey, 
                    input_state: state.puma_state.input_state,
                    analysis_state:state.puma_state.analysis_state,
                    vis_state:state.puma_state.vis_state,
                    target_state:action.target_state,
                    gene_enrichment_state : state.puma_state.gene_enrichment_state,
                    download_state:state.puma_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_puma_state3123);
            return new_puma_state3123;
            break
            case 'SET_PUMA_GENE_ENRICHMENT_STATE':
                const new_puma_state31234 = { ...state, 
                    puma_state: {
                        numAnalysis: state.puma_state.numAnalysis,
                        activeKey: state.puma_state.activeKey, 
                        input_state: state.puma_state.input_state,
                        analysis_state:state.puma_state.analysis_state,
                        vis_state:state.puma_state.vis_state,
                        target_state:state.puma_state.target_state,
                        gene_enrichment_state : action.gene_enrichment_state,
                        download_state:state.puma_state.download_state
                    } 
                };
                //console.log("#############state##########################");
                //console.log(new_puma_state31234);
                return new_puma_state31234;
                break
         case 'SET_PUMA_DOWN_STATE':
             const new_puma_state4 = { ...state, 
                 puma_state: {
                    numAnalysis: state.puma_state.numAnalysis,
                    activeKey: state.puma_state.activeKey,
                     input_state: state.puma_state.input_state,
                     analysis_state:state.puma_state.analysis_state,
                     vis_state:state.puma_state.vis_state,
                     target_state: state.puma_state.target_state,
                     gene_enrichment_state : state.puma_state.gene_enrichment_state,
                     download_state:action.download_state
                 } 
             };
             //console.log("#############state##########################");
             //console.log(new_puma_state4);
             return new_puma_state4;
             break
     //############################ PUMA STATES & ACTION END ######################################



     //############################ OTTER STATES & ACTION START ###################################
      case 'SET_OTTER_INPUT_STATE':
            const new_state_otter1  =  { ...state, 
                otter_state: {
                    numAnalysis: state.otter_state.numAnalysis,
                    input_state: action.input_state,
                    analysis_state:state.otter_state.analysis_state,
                    vis_state:state.otter_state.vis_state,
                    download_state:state.otter_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_otter1);
            return new_state_otter1;
            break
    case 'SET_OTTER_INPUT_STATE_UID':
        const new_state_otter_uid  =  { ...state, 
            otter_state: {
                numAnalysis: state.otter_state.numAnalysis,
                input_state: {
                    submit_disabled : state.otter_state.input_state.submit_disabled,
                    input_files: state.otter_state.input_state.input_files ,
                    uid: action.uid   
                },
                analysis_state:state.otter_state.analysis_state,
                vis_state:state.otter_state.vis_state,
                download_state:state.otter_state.download_state
            } 
        };
        //console.log("#############state##########################");
        //console.log(new_state_otter_uid);
        return new_state_otter_uid;
        break
        case 'SET_OTTER_NUM_ANALYSIS':
            const new_state_otter1234  =  { ...state, 
                otter_state: {
                    numAnalysis: action.numAnalysis,
                    input_state: state.otter_state.input_state,
                    analysis_state:state.otter_state.analysis_state,
                    vis_state:state.otter_state.vis_state,
                    download_state:state.otter_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_otter1234);
            return new_state_otter1234;
            break
        case 'SET_OTTER_ANALYSIS_STATE':
            const new_state_otter2 = { ...state, 
                otter_state: {
                    numAnalysis: state.otter_state.numAnalysis,
                    input_state: state.otter_state.input_state,
                    analysis_state:action.analysis_state,
                    vis_state:state.otter_state.vis_state,
                    download_state:state.otter_state.download_state
                    } 
                };
            //console.log("#############state##########################");
            //console.log(new_state_otter2);
            return new_state_otter2;
            break
        case 'SET_OTTER_VIS_STATE':
            const new_state_otter3 = { ...state, 
                otter_state: {
                    numAnalysis: state.otter_state.numAnalysis,
                    input_state: state.otter_state.input_state,
                    analysis_state:state.otter_state.analysis_state,
                    vis_state:action.vis_state,
                    download_state:state.otter_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_otter3);
            return new_state_otter3;
            break
        case 'SET_OTTER_VIS_STATE_TAB_DISABLED':
            const new_state_otter31_tab = { ...state, 
                otter_state: {
                numAnalysis: state.otter_state.numAnalysis,
                input_state: state.otter_state.input_state,
                analysis_state:state.otter_state.analysis_state,
                vis_state:{
                    vis_tab_disabled:action.vis_tab_disabled,
                    otter_results_table_data_full:state.otter_state.vis_state.otter_results_table_data_full,
                    otter_results_table_data_trimmed:state.otter_state.vis_state.otter_results_table_data_trimmed,
                    table_type: state.otter_state.vis_state.table_type,
                    network_data: state.otter_state.vis_state.network_data
                },
                download_state:state.otter_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_state_otter31_tab);
            return new_state_otter31_tab;
            break
        
        case 'SET_OTTER_VIS_STATE_TABLE_TYPE':
            const new_state_otter31 = { ...state, 
                otter_state: {
                numAnalysis: state.otter_state.numAnalysis,
                input_state: state.otter_state.input_state,
                analysis_state:state.otter_state.analysis_state,
                vis_state:{
                    vis_tab_disabled:state.otter_state.vis_state.vis_tab_disabled,
                    otter_results_table_data_full:action.otter_results_table_data_full,
                    otter_results_table_data_trimmed:state.otter_state.vis_state.otter_results_table_data_trimmed,
                    table_type:action.table_type,
                    network_data: state.otter_state.vis_state.network_data
                },
                download_state:state.otter_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_state_otter31);
            return new_state_otter31;
            break
        case 'SET_OTTER_VIS_STATE_TABLE_DATA_FULL':
            const new_state_otter32 = { ...state, 
                otter_state: {
                    numAnalysis: state.otter_state.numAnalysis,
                    input_state: state.otter_state.input_state,
                    analysis_state:state.otter_state.analysis_state,
                    vis_state:{
                        vis_tab_disabled:state.otter_state.vis_state.vis_tab_disabled,
                        otter_results_table_data_full:action.otter_results_table_data_full,
                        otter_results_table_data_trimmed:state.otter_state.vis_state.otter_results_table_data_trimmed,
                        table_type:state.otter_state.vis_state.table_type,
                        network_data: state.otter_state.vis_state.network_data
                    },
                    download_state:state.otter_state.download_state
                    } 
                };
            //console.log("#############state##########################");
            //console.log(new_state_otter32);
            return new_state_otter32;
            break
        
        case 'SET_OTTER_VIS_STATE_TABLE_DATA_TRIMMED':
            const new_state_otter33_2 = { ...state, 
                otter_state: {
                    numAnalysis: state.otter_state.numAnalysis,
                    input_state: state.otter_state.input_state,
                    analysis_state:state.otter_state.analysis_state,
                    vis_state:{
                        vis_tab_disabled:state.otter_state.vis_state.vis_tab_disabled,
                        otter_results_table_data_full:state.otter_state.vis_state.otter_results_table_data_full,
                        otter_results_table_data_trimmed:action.otter_results_table_data_trimmed,
                        table_type:state.otter_state.vis_state.table_type,
                        network_data: state.otter_state.vis_state.network_data},
                    download_state:state.otter_state.download_state} 
                };
            //console.log("#############state##########################");
            //console.log(new_state_otter33_2);
            return new_state_otter33_2;
            break
        case 'SET_OTTER_VIS_STATE_NETWORK_DATA':
            const new_state_otter34 = { ...state, 
                otter_state: {
                numAnalysis: state.otter_state.numAnalysis,
                input_state: state.otter_state.input_state,
                analysis_state:state.otter_state.analysis_state,
                vis_state:{
                    vis_tab_disabled:state.otter_state.vis_state.vis_tab_disabled,
                    otter_results_table_data_full:action.otter_results_table_data_full,
                    otter_results_table_data_trimmed:state.otter_state.vis_state.otter_results_table_data_trimmed,
                    table_type:state.otter_state.vis_state.table_type,
                    network_data: action.network_data },
                download_state:state.otter_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_state_otter34);
            return new_state_otter34;
            break
        case 'SET_OTTER_DOWN_STATE':
            const new_state_otter4 = { ...state, 
                otter_state: {
                    numAnalysis: state.otter_state.numAnalysis,
                    input_state: state.otter_state.input_state,
                    analysis_state:state.otter_state.analysis_state
                    ,vis_state:state.otter_state.vis_state,
                    download_state:action.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_otter4);
            return new_state_otter4;
            break     
     //############################ OTTER STATES & ACTION END ###################################

      //############################ DRAGON STATES & ACTION START ###################################
        case 'SET_DRAGON_INPUT_STATE':
            const new_state_dragon1  =  { ...state, 
                dragon_state: {
                    numAnalysis: state.dragon_state.numAnalysis,
                    input_state: action.input_state,
                    analysis_state:state.dragon_state.analysis_state,
                    vis_state:state.dragon_state.vis_state,
                    download_state:state.dragon_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_dragon1);
            return new_state_dragon1;
            break
        case 'SET_DRAGON_INPUT_STATE_UID':
            const new_state_dragon_uid =  { ...state, 
                dragon_state: {
                    numAnalysis: state.dragon_state.numAnalysis,
                    input_state: {
                        submit_disabled : state.dragon_state.input_state.submit_disabled,
                        input_files: state.dragon_state.input_state.input_files ,
                        uid: action.uid   
                    },
                    analysis_state:state.dragon_state.analysis_state,
                    vis_state:state.dragon_state.vis_state,
                    download_state:state.dragon_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_dragon_uid);
            return new_state_dragon_uid;
            break
            case 'SET_DRAGON_NUM_ANALYSIS':
                const new_state_dragon1234  =  { ...state, 
                    dragon_state: {
                        numAnalysis: action.numAnalysis,
                        input_state: state.dragon_state.input_state,
                        analysis_state:state.dragon_state.analysis_state,
                        vis_state:state.dragon_state.vis_state,
                        download_state:state.dragon_state.download_state
                    } 
                };
                //console.log("#############state##########################");
                //console.log(new_state_dragon1234);
                return new_state_dragon1234;
                break
        case 'SET_DRAGON_ANALYSIS_STATE':
            const new_state_dragon2 = { ...state, 
                dragon_state: {
                    numAnalysis: state.dragon_state.numAnalysis,
                    input_state: state.dragon_state.input_state,
                    analysis_state:action.analysis_state,
                    vis_state:state.dragon_state.vis_state,
                    download_state:state.dragon_state.download_state
                    } 
                };
            //console.log("#############state##########################");
            //console.log(new_state_dragon2);
            return new_state_dragon2;
            break
        case 'SET_DRAGON_VIS_STATE':
            const new_state_dragon3 = { ...state, 
                dragon_state: {
                    numAnalysis: state.dragon_state.numAnalysis,
                    input_state: state.dragon_state.input_state,
                    analysis_state:state.dragon_state.analysis_state,
                    vis_state:action.vis_state,
                    download_state:state.dragon_state.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_dragon3);
            return new_state_dragon3;
            break
        case 'SET_DRAGON_VIS_STATE_TAB_DISABLED':
            const new_state_dragon31_tab = { ...state, 
                dragon_state: {
                numAnalysis: state.dragon_state.numAnalysis,
                input_state: state.dragon_state.input_state,
                analysis_state:state.dragon_state.analysis_state,
                vis_state:{
                    vis_tab_disabled:action.vis_tab_disabled,
                    dragon_results_table_data_full:state.dragon_state.vis_state.dragon_results_table_data_full,
                    dragon_results_table_data_trimmed:state.dragon_state.vis_state.dragon_results_table_data_trimmed,
                    table_type: state.dragon_state.vis_state.table_type,
                    network_data: state.dragon_state.vis_state.network_data
                },
                download_state:state.dragon_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_state_dragon31_tab);
            return new_state_dragon31_tab;
            break
        
        case 'SET_DRAGON_VIS_STATE_TABLE_TYPE':
            const new_state_dragon31 = { ...state, 
                dragon_state: {
                numAnalysis: state.dragon_state.numAnalysis,
                input_state: state.dragon_state.input_state,
                analysis_state:state.dragon_state.analysis_state,
                vis_state:{
                    vis_tab_disabled:state.dragon_state.vis_state.vis_tab_disabled,
                    dragon_results_table_data_full:action.dragon_results_table_data_full,
                    dragon_results_table_data_trimmed:state.dragon_state.vis_state.dragon_results_table_data_trimmed,
                    table_type:action.table_type,
                    network_data: state.dragon_state.vis_state.network_data
                },
                download_state:state.dragon_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_state_dragon31);
            return new_state_dragon31;
            break
        case 'SET_DRAGON_VIS_STATE_TABLE_DATA_FULL':
            const new_state_dragon32 = { ...state, 
                dragon_state: {
                    numAnalysis: state.dragon_state.numAnalysis,
                    input_state: state.dragon_state.input_state,
                    analysis_state:state.dragon_state.analysis_state,
                    vis_state:{
                        vis_tab_disabled:state.dragon_state.vis_state.vis_tab_disabled,
                        dragon_results_table_data_full:action.dragon_results_table_data_full,
                        dragon_results_table_data_trimmed:state.dragon_state.vis_state.dragon_results_table_data_trimmed,
                        table_type:state.dragon_state.vis_state.table_type,
                        network_data: state.dragon_state.vis_state.network_data
                    },
                    download_state:state.dragon_state.download_state
                    } 
                };
            //console.log("#############state##########################");
            //console.log(new_state_dragon32);
            return new_state_dragon32;
            break
        
        case 'SET_DRAGON_VIS_STATE_TABLE_DATA_TRIMMED':
            const new_state_dragon33_2 = { ...state, 
                dragon_state: {
                    numAnalysis: state.dragon_state.numAnalysis,
                    input_state: state.dragon_state.input_state,
                    analysis_state:state.dragon_state.analysis_state,
                    vis_state:{
                        vis_tab_disabled:state.dragon_state.vis_state.vis_tab_disabled,
                        dragon_results_table_data_full:state.dragon_state.vis_state.dragon_results_table_data_full,
                        dragon_results_table_data_trimmed:action.dragon_results_table_data_trimmed,
                        table_type:state.dragon_state.vis_state.table_type,
                        network_data: state.dragon_state.vis_state.network_data},
                    download_state:state.dragon_state.download_state} 
                };
            //console.log("#############state##########################");
            //console.log(new_state_dragon33_2);
            return new_state_dragon33_2;
            break
        case 'SET_DRAGON_VIS_STATE_NETWORK_DATA':
            const new_state_dragon34 = { ...state, 
                dragon_state: {
                numAnalysis: state.dragon_state.numAnalysis,
                input_state: state.dragon_state.input_state,
                analysis_state:state.dragon_state.analysis_state,
                vis_state:{
                    vis_tab_disabled:state.dragon_state.vis_state.vis_tab_disabled,
                    dragon_results_table_data_full:action.dragon_results_table_data_full,
                    dragon_results_table_data_trimmed:state.dragon_state.vis_state.dragon_results_table_data_trimmed,
                    table_type:state.dragon_state.vis_state.table_type,
                    network_data: action.network_data },
                download_state:state.dragon_state.download_state} 
            };
            //console.log("#############state##########################");
            //console.log(new_state_dragon34);
            return new_state_dragon34;
            break
        case 'SET_DRAGON_DOWN_STATE':
            const new_state_dragon4 = { ...state, 
                dragon_state: {
                    numAnalysis: state.dragon_state.numAnalysis,
                    input_state: state.dragon_state.input_state,
                    analysis_state:state.dragon_state.analysis_state
                    ,vis_state:state.dragon_state.vis_state,
                    download_state:action.download_state
                } 
            };
            //console.log("#############state##########################");
            //console.log(new_state_dragon4);
            return new_state_dragon4;
            break
        //############################ DRAGON STATES & ACTION END ###################################



         default:
             return state;
 
     }
 }
 export default reducer;