import 'bootstrap/dist/css/bootstrap.min.css';
import React,{ useState,useEffect,Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
 
} from "react-router-dom";
import Container from 'react-bootstrap/Container';


import Header from './components/Elements/Header';
import Footer from './components/Elements/Footer';
import BigBracket from './components/Elements/BigBracket';


import { createBrowserHistory } from "history";
const PandaApp = React.lazy(() => import('./components/Panda/PandaApp'));
const OtterApp = React.lazy(() => import('./components/Otter/OtterApp'));
const PumaApp = React.lazy(() => import('./components/Puma/PumaApp'));
const CondorApp = React.lazy(() => import('./components/Condor/CondorApp'));
const AlpacaApp = React.lazy(() => import('./components/Alpaca/AlpacaApp'));
const DragonApp = React.lazy(() => import('./components/Dragon/DragonApp'));
const MonsterApp = React.lazy(() => import('./components/Monster/MonsterApp'));
const CraneApp = React.lazy(() => import('./components/Crane/CraneApp'));
const LionessApp = React.lazy(() => import('./components/Lioness/LionessApp'));

const NetworkReconstructionHome = React.lazy(() => import('./components/Home/NetworkReconstructionHome2'));
const CommunityDetectionHome = React.lazy(() => import('./components/Home/CommunityDetectionHome2'));
const DifferentialAnalysisHome = React.lazy(() => import('./components/Home/DifferentialAnalysisHome2'));
const FunctionHome = React.lazy(() => import('./components/Home/FunctionHome2'));
const Pipeline = React.lazy(() => import('./components/Home/Pipeline2'));
const Contact = React.lazy(() => import('./components/Contact/Contact'));
const Help = React.lazy(() => import('./components/Help/Help'));
const RecountApp = React.lazy(() => import('./components/Home/RecountApp.js'));



function App() {
  const [animalName,setAnimalName] = useState("Animal");
  const [functionName,setFunctionName] = useState("Function");
  const history = createBrowserHistory();
  const url = history.location.pathname;
  
 
  
  const setAnimalAndFunctionName = ()=>{
    
    // const url = window.location.href;
    if (url.includes("network")){
      setAnimalName("Animal");
      setFunctionName("Network Reconstruction");

    }
    else if (url.includes("community")){
      setAnimalName("Animal");
      setFunctionName("Community Detection");

    }
    else if (url.includes("differential")){
      setAnimalName("Animal");
     setFunctionName("Differential Analysis");
      
    }
    else if(url.includes("panda")){
      setAnimalName("Panda");
      setFunctionName("Function");

    }
    else if(url.includes("lioness")){
      setAnimalName("Lioness");
      setFunctionName("Function");

    }
    else if(url.includes("puma")){
      setAnimalName("Puma");
      setFunctionName("Function");

    }
    else if(url.includes("otter")){
      setAnimalName("Otter");
      setFunctionName("Function");

    }
    else if(url.includes("dragon")){
      setAnimalName("Dragon");
      setFunctionName("Function");

    }
    else if(url.includes("condor")){
      setAnimalName("Condor");
      setFunctionName("Function");

    }
    else if(url.includes("alpaca")){
      setAnimalName("Alpaca");
      setFunctionName("Function");

    }
    else if(url.includes("monster")){
      setAnimalName("Monster");
      setFunctionName("Function");

    }
    else if(url.includes("crane")){
      setAnimalName("Crane");
      setFunctionName("Function");

    }
    // else if(url.includes("pipeline")){
      
    //   setFunctionName("Pipeline");

    // }
   
  }
  useEffect(setAnimalAndFunctionName,[]);
  
  
  return (
    <Router> 
    <div className="App wrapper flex-grow-1">
    <Container fluid style={{padding:0}}  >
    <Header animal_name={animalName=== 'Animal' ? 'Method' : animalName.toUpperCase()} function_name={functionName}></Header>
      <Switch>
        <Route path="/recount3">
        <Container>
        <Suspense fallback={<div><div>Loading Recount...</div><BigBracket n={23}/></div>}>
        <RecountApp/>
        </Suspense>
        </Container>
          </Route>
        
      
        <Route path="/alpaca">
        <Container>
        <Suspense fallback={<div><div>Loading ALPACA...</div><BigBracket n={23}/></div>}>
        <AlpacaApp/>
        </Suspense>
        </Container>
          
       
        </Route>
        <Route path="/panda">
        <Container>
          <Suspense fallback={<div><div>Loading PANDA...</div><BigBracket n={23}/></div>}>
          <PandaApp />
        </Suspense>
        </Container>
        
       </Route>
       
       

       
      
       
        <Route path="/condor">
        <Container>
        <Suspense fallback={<div><div>Loading CONDOR...</div><BigBracket n={23}/></div>}>
          <CondorApp/>
        </Suspense>
        </Container>

       
        
        </Route>
        <Route path="/puma">
          <Container>
            <Suspense fallback={<div><div>Loading PUMA...</div><BigBracket n={23}/></div>}>
            <PumaApp/>
            </Suspense>
          </Container>          
        </Route>
       
        <Route path="/lioness">
          <Container>
            <Suspense fallback={<div><div>Loading LIONESS...</div><BigBracket n={23}/></div>}>
            <LionessApp/>
          
            </Suspense>
          </Container>

       
        
        </Route>
        <Route path="/otter">
        <Container>
        <Suspense fallback={<div><div>Loading OTTER...</div><BigBracket n={23}/></div>}>
        <OtterApp/>
        
       </Suspense>
       </Container>

    
          
       
        </Route>
        <Route path="/dragon">
        <Container>
        <Suspense fallback={<div><div>Loading DRAGON...</div><BigBracket n={23}/></div>}>
        <DragonApp/>
        </Suspense>
        </Container>
       
        
        </Route>
        <Route path="/monster">
        <Container>
        <Suspense fallback={<div><div>Loading MONSTER...</div><BigBracket n={23}/></div>}>
        <MonsterApp/>
       </Suspense>
       </Container>
       
        
        </Route>
        <Route path="/crane">
        <Container>
        <Suspense fallback={<div><div>Loading CRANE..</div><BigBracket n={23}/></div>}>
        <CraneApp/>
       </Suspense>
       </Container>
        
        </Route>
        <Route path="/network">
        <Container>
        <Suspense fallback={<div><div>Loading Network Page...</div><BigBracket n={23}/></div>}>
        <NetworkReconstructionHome/>
        <BigBracket n={8}/>
       </Suspense>
       </Container>
        </Route>
        <Route path="/community">
        <Container>
        <Suspense fallback={<div><div>Loading Community Page...</div><BigBracket n={23}/></div>}>
        <CommunityDetectionHome/>
        <BigBracket n={14}/>
       </Suspense>
       </Container>
          
        </Route>
        <Route path="/differential">
        <Container>
        <Suspense fallback={<div><div>Loading Differential Analysis Page...</div><BigBracket n={23}/></div>}>
        <DifferentialAnalysisHome/>
        <BigBracket n={14}/>
        </Suspense>
        </Container>
        </Route>
        
        {/* <Route path="/pipeline">
        <Container>
        <Suspense fallback={<div><div>Loading Pipeline Page...</div><BigBracket n={23}/></div>}>
        <Pipeline/>
        </Suspense>
        <BigBracket n={10}/>
        </Container>
        </Route> */}
        
        <Route path="/contact">
        <Container>
        <Suspense fallback={<div><div>Loading Contact Page...</div><BigBracket n={23}/></div>}>
        <Contact/>
        </Suspense>
        </Container>
        </Route>
        <Route path="/help">
        <Container>
        <Suspense fallback={<div><div>Loading Help Page...</div><BigBracket n={23}/></div>}>
        <Help/>
        </Suspense>
        </Container>
        </Route>
        
        
        
       

        
    
        <Route path="/">
        <Suspense fallback={<div><div>Loading Home Page...</div><BigBracket n={23}/></div>}>
        <div className='wrapper flex-grow-1' style={{flexGrow:1,width:'100%',backgroundColor:'#ffff'}}>

        <header className="jumbotron jumbotron-fluid" style={{backgroundColor:'#ffff'}}>
        <Container fluid style={{textAlign:'center',width:'100%'}}>
          <p className='lead'>
          netZooCloud is a web server that runs <a target="_blank" href="https://netzoo.github.io/">netZoo</a> tools for gene regulatory network inference and analysis on the cloud.
          </p>
          <p>
          Current version: <a target="_blank" href="https://github.com/netZoo/netzoocloud/releases/tag/0.8.0">0.8.0.</a> Last update: 13/10/2023.
          </p>
        </Container>
        </header>
        </div>
        
        <FunctionHome/>


       </Suspense>
       
        </Route>

        
      </Switch>
      </Container>
      <BigBracket n={3}/>
      <Footer/>
    </div>
    </Router>
  );
}

export default App;