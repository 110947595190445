function BigBracket({n=1}){
    var brackets = [];
    for (var i = 0; i < n ; i++){
        brackets.push(<br key={i}></br>)
    }
    return (
    <div>
     {brackets.map(item=>item)}

    </div>)
}
export default BigBracket;