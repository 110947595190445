import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';

import netZooLogo from "../../Images/netZooLogos/netZoo_logo_v14.svg";
import cogSolid from "./Images/cog-solid.svg";
import githubLogo from "./Images/github-brands.svg"
import Icon from '../Elements/Icon';
import Counter from './Counter/Counter';
import './styles/header.css';


function Header({animal_name,function_name}) {
    var color1 = '#6c757d';
    // 
    return (

            
                <Navbar className="navbar bg-secondary navbar-expand-sm"  style={{backgroundColor:color1}} >
                <Container   style={{backgroundColor:color1}}>

                    <Icon href="/" w={150} h ={150} src_url={netZooLogo} caption={""}></Icon>
                            
                    <Navbar.Toggle style={{color:'rgba(255,255,255,.55)'}} aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav style={{color:'rgba(255,255,255,.55)'}} className="mr-auto">
                        <Nav.Link style={{marginTop:'1%',color:'rgba(255,255,255,.55)'}} href="/">Home</Nav.Link>
                        <NavDropdown style={{marginTop:'1%',color:'rgba(255,255,255,.55)'}} title={animal_name} id="basic-nav-dropdown">
                            <NavDropdown.Item   href="/alpaca">ALPACA</NavDropdown.Item>  
                            <NavDropdown.Item   href="/panda">PANDA</NavDropdown.Item>
                            <NavDropdown.Item   href="/condor">CONDOR</NavDropdown.Item>
                            <NavDropdown.Item   href="/crane">CRANE</NavDropdown.Item>
                            <NavDropdown.Item   href="/puma">PUMA</NavDropdown.Item>
                            <NavDropdown.Item   href="/lioness">LIONESS</NavDropdown.Item>
                            <NavDropdown.Item   href="/otter">OTTER</NavDropdown.Item>
                            <NavDropdown.Item   href="/dragon">DRAGON</NavDropdown.Item>
                            <NavDropdown.Item   href="/monster">MONSTER</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown style={{marginTop:'1%',color:'rgba(255,255,255,.55)'}} title={function_name} id="basic-nav-dropdown">
                            <NavDropdown.Item   href="/network">Network Reconstruction</NavDropdown.Item>
                            <NavDropdown.Item   href="/community">Community Detection</NavDropdown.Item>
                            <NavDropdown.Item   href="/differential">Differential Analysis</NavDropdown.Item>
                            {/* <NavDropdown.Item   href="/pipeline">Pipeline</NavDropdown.Item> */}
                            <NavDropdown.Item   href="/recount3">Recount</NavDropdown.Item>
                        
                        </NavDropdown>
                        <Nav.Link style={{marginTop:'1%',color:'rgba(255,255,255,.55)'}} href="/help">Help</Nav.Link>
                        
                        </Nav>
                    
                        <Counter/>
                    
                        
    
                        <NavDropdown style={{marginBottom:'2s%',color:'rgba(255,255,255,.55)'}} title={<img src={ cogSolid}style={{width: "15px"}}></img>}>
                        
                        <NavDropdown.Item target="_blank"   href="https://github.com/netZoo/netzoocloud"><img src={githubLogo}style={{width: "10px"}}></img> GitHub</NavDropdown.Item>  
                        <NavDropdown.Item target="_blank"   href="/api/docs">API</NavDropdown.Item>
                        <NavDropdown.Item target="_blank"  href="/contact">Contact</NavDropdown.Item>
                        
                        
                        </NavDropdown>

                    
                
                        
                    </Navbar.Collapse>
                    </Container>

                </Navbar>
    )
}

export default Header
