import React from 'react';
import Figure from 'react-bootstrap/Figure';




function Icon({src_url,caption,w,h,href}) {
    return (
        <div>
            <a href={href}>
            <Figure >
            <Figure.Caption>
                {caption}
            </Figure.Caption>
            <Figure.Image
                width={w}
                height={h}
                src={src_url}
            />
            
            </Figure>
            </a>
        </div>
    )
}

export default Icon
