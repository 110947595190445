import {React,useEffect,useState} from 'react';
import { useStateValue } from "../../../StateManagement/GlobalStateProvider";
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



function Counter() {
    const [state] = useStateValue();
    const [showSpinner,setShowSpinner] = useState(true)
    // const [numberofAnalysis,setNumberOfAnalysis] = useState(state.panda_state.numAnalysis + state.lioness_state.numAnalysis + state.puma_state.numAnalysis + state.otter_state.numAnalysis + state.dragon_state.numAnalysis)
    const [numberofAnalysis,setNumberOfAnalysis] = useState(state.panda_state.numAnalysis+state.lioness_state.numAnalysis,state.puma_state.numAnalysis+state.otter_state.numAnalysis+state.dragon_state.numAnalysis+state.condor_state.numAnalysis+state.alpaca_state.numAnalysis);
    const getNumberOfAnalysis = ()=>{
        const backend_address = process.env.REACT_APP_BACKEND_URL;
        const url = backend_address + `/api/analysis/getNumberOfAnalysis/`;
        fetch(url, { // Your POST endpoint
                    method: 'GET',
                    }
                )
        .then(response => response.json())
        .then((data)=>{
            setShowSpinner(false);
            //console.log("###COUNTER DATA#######");
            //console.log(data);
            setNumberOfAnalysis(data["numAnalysis"]);
        });
    }
        

useEffect(getNumberOfAnalysis,[state.panda_state.numAnalysis,state.lioness_state.numAnalysis,state.puma_state.numAnalysis,state.otter_state.numAnalysis,state.dragon_state.numAnalysis,state.condor_state.numAnalysis,state.alpaca_state.numAnalysis])
        
    // useEffect(getNumberOfAnalysis,numberofAnalysis);
    return (
        <div style={{marginTop:'1%',color:'rgba(255,255,255,.55)'}}>
          <Row>
              <Col>Analysis Completed</Col>
            
              
              <Col>
              {showSpinner ?  <Spinner animation="border" /> : numberofAnalysis}
              </Col>
        </Row>  
         
            
        </div>
    )
}

export default Counter
