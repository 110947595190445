import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StateProvider } from "./StateManagement/GlobalStateProvider.js";
import reducer, { initialState } from "./StateManagement/GlobalReducer.js";
// if (process.env.VERBOSE !== "TRUE"){
//   console.log = () => {}
//   console.error = () => {}
//   console.debug = () => {}
//   console.warn = () => {}
//   console.info = () => {}

// }
    
ReactDOM.render(
  <React.StrictMode>
     <StateProvider initialState={initialState} reducer={reducer}>
      <App />
      </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
